
// import alert from "../../helpers/alert";
import response from '../../configs/message_response'
import ProfileService from "../../services/profile.service";
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import router from "../../router";
import { getToastError, getToastSuccess} from "../../helpers/toast";
const initialState = {
    profile: null
};

export default {
    namespaced: true,
    state: initialState,
    actions:
    {
        getProfile: async({commit}) =>
        {
            var result = null
            try 
            {
                result = await ProfileService.getProfile()
            }
            catch (error) 
            {
                // alert.getAlert('Server đang bảo trì', 'error')
                return
            }
            
            if(_isEmpty(result))
            {
                // alert.getAlert('Đã có lỗi xảy ra trong quá trình lấy thông tin của bạn vui lòng thử lại', 'error')
                return
            }
            
            if(_get(result, 'status') == 'error')
            {
                if(_get(response,`public.${result.message}.auth`))
                {
                    // alert.getAlert(_get(response,`public.${result.message}.message`),'error')
                    // setTimeout(() => {
                    //     localStorage.removeItem('jwt')
                    //     router.push('/')
                    // }, 2000);
                    localStorage.removeItem('jwt')
                    router.push('/')
                    return
                }
                // alert.getAlert('Đã có lỗi xảy ra trong quá trình lấy thông tin của bạn vui lòng thử lại', 'error')
                return;
            }
            commit('setProfile',_get(result, 'data'))
            localStorage.setItem('user_id', (_get(result, 'data.cid')))
            return
        },
        
        getUpdateProfile: async({dispatch}, value) =>
        {
            
            var result = null
            try 
            {
                result = await ProfileService.getUpdateProfile(value)
            } 
            catch (error) 
            {
                getToastError(_get(response, 'public.SERVER.message'),2000)
                return
            }
            
            if(_isEmpty(result))
            {
                // (_get(response, 'public.SERVER.message'),2000)
                return
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    router.push('/')
                    return 
                }
                getToastError('Đường dẫn mạng xã hội của bạn không hợp lệ',2000)
                return
            }
            getToastSuccess('Cập nhật thông tin cá nhân thành công', 2000)
            dispatch('getProfile')
        },

        getFollowService: async({dispatch}, value) =>
        {
            var result = null
            try 
            {
                result = await ProfileService.getFollowService(value)
            } 
            catch (error) 
            {
                getToastError(_get(response, 'public.SERVER.message'),2000)
                return
            }
            
            if(_isEmpty(result))
            {
                return
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    router.push('/')
                }
                return
            }
            dispatch('getProfile')
        },

        
        getUnFollowService: async({dispatch}, value) =>
        {
            var result = null
            try 
            {
                result = await ProfileService.getUnFollowService(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER',2000)
                return
            }
            
            if(_isEmpty(result))
            {
                return
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    router.push('/')
                }
                return
            }
            dispatch('getProfile')
        }
    },
    
    mutations: 
    {
        setProfile: (state,value) =>
        {
            state.profile = value
        }
    }
}
