

export const convertDateToTimestamp = (value) => {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return "Đang cập nhật";
    }
    const timestamp = Math.floor(date.getTime() / 1000);
    return timestamp;
  };


export const convertTimestamp = (timestamp) => {
    if (!timestamp) {
        return '--/--/----';
    }

    var time = timestamp * 1000;
    var date = new Date(time);

    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var year = date.getFullYear();

    var formattedDate = day + '/' + month + '/' + year;

    return formattedDate;
};

export const convertTimestampToDateString = value =>
{
    const timestamp = value * 1000; // Nhân 1000 để chuyển từ giây sang mili-giây
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleString('en-US', options);
}

export const convertTimestampToDateHoursString = value =>
{
    const timestamp = value * 1000; // Nhân 1000 để chuyển từ giây sang mili-giây
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
}


export const convertTimestampToHours = (value) => {
    const timestamp = value * 1000;
    const date = new Date(timestamp);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const timeString = date.toLocaleString('en-US', options);
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':');

    const formattedHours = hours.padStart(2, '0'); // Thêm số 0 nếu giờ chỉ có 1 chữ số

    return `${formattedHours}:${minutes} ${period}`;
};


export const getPercent = (newValue, oldValue) => {
    var percent = (Number(newValue) - Number(oldValue));
    var result = (percent / Math.abs(oldValue)) * 100;

    if(oldValue == newValue)
    {
        return {
            percent: Number(0).toFixed(2),
            type: 'none'
        }
    }   

    if(oldValue == newValue)
    {
        return {
            percent: Number(0).toFixed(2),
            type: 'none'
        }
    }   

    if (oldValue == 0 || !oldValue)
    {
        return {
            percent: Number(100).toFixed(2).toLocaleString('de-de'),
            type: 'up'
        }
    }

    if(isNaN(result) || !isFinite(result))
    {
        return {
            percent: Number(0).toFixed(2).toLocaleString('de-de'),
            type: 'none'
        }
    }

    // if( !isFinite(result))
    // {
    //     return {
    //         percent: '+' + Number(0).toFixed(2),
    //         type: 'none'
    //     }
    // }

    if (result > 0) 
    {
        return {
            percent: result.toFixed(2).toLocaleString('de-de'),
            type: 'up'
        }
    } 
    else if (result < 0) 
    {
        return {
            percent: Math.abs(Number(result.toFixed(2))).toLocaleString('de-de'),
            type: 'down'
        }
    }

    return {
        percent: result.toFixed(2).toLocaleString('de-de'),
        type: 'none'
    }
}

export const getAverage = (number1, number2) =>
    {
        const result = number1/number2
        if (number2 == 0)
        {
            return 0
        }
    
        if(isNaN(result) || !isFinite(result))
        {
            return 0
        }
        return result
    }



export function formatNumber(number) 
{
    let formattedNumber;
    let billions;
    let millions;
    let thousands;


    if (1000000000 <= number) 
    {
        billions = Math.floor(number / 1000000000)
        millions = Math.floor((number % 1000000000) / 1000000)
        thousands = Math.floor((number % 1000000) / 1000)
        formattedNumber = { billions: billions, millions: millions, thousands: thousands }
    }

    else if (1000000 <= number) 
    {
        millions = Math.floor(number / 1000000);
        thousands = Math.floor((number % 1000000) / 1000);
        formattedNumber = { millions: millions, thousands: thousands }
    }

    else if (1000 <= number) 
    {
        formattedNumber = { thousands: Math.floor(number / 1000) }
    }

    return formattedNumber
    
}


export function addLeadingZeros(number) {
    // Chuyển số thành chuỗi
    const numberString = String(number);
  
    // Kiểm tra số chữ số của số
    const digitCount = numberString.length;
  
    // Thêm số 0 vào trước số nếu có một chữ số
    if (digitCount === 1) {
      return '00' ;
    }
    // Thêm số 0 vào trước số nếu có hai chữ số
    else if (digitCount === 2) {
      return '0' ;
    }
  
    // Trả về số ban đầu nếu có nhiều hơn hai chữ số
    return '';
  }


export const formatNumberNomarl = (number) => 
{
    if (number >= 1000) 
    {
        return (number / 1000).toLocaleString('vi') + 'k';
    } 
    return number.toString();
}
// timestamp 121234213423 sang "31 JUL, 1973"
export const fotmatDateToString = (timestamp) =>
{
    if(!timestamp)
    {
        return "Đang cập nhật"
    }
    const date = new Date(timestamp);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate.toUpperCase();
}



export const getTwoWeeksAgoTimestamp = () =>
{
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    const timestamp = Math.floor(twoWeeksAgo.getTime() / 1000); // Chia 1000 để chuyển từ millisecond sang giây

    return timestamp;
  }

export const getOneWeeksAgoTimestamp = () =>
{
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 7);
    const timestamp = Math.floor(twoWeeksAgo.getTime() / 1000); // Chia 1000 để chuyển từ millisecond sang giây
    return timestamp;
  }

export const convertTimestampToQuarter = (timestamp) => 
{
    if (!timestamp)
    {
        return "Đang cập nhật."
    }
    const date = new Date(timestamp * 1000);
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0'); // Thêm số 0 vào trước ngày nếu chỉ có một chữ số
    const year = date.getFullYear()
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}

export const convertTimestampToQuarterHasYear = (timestamp) => 
{
    if (!timestamp)
    {
        return "Đang cập nhật."
    }
    const date = new Date(timestamp * 1000);
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const month = months[date.getMonth()];
    const year = date.getFullYear()
    const day = date.getDate().toString().padStart(2, '0'); // Thêm số 0 vào trước ngày nếu chỉ có một chữ số

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}


export const dateToTimestamp = dateString =>
{
    const timestamp = Date.parse(dateString);
    return timestamp
}

export const  handleUpdatingValue = (value) => 
{
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
}


export const  convertToLowerCase = (str) => 
{
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, '')
    .toLowerCase();         
}

function isPhoneNumber(input) {
    const phonePattern = /^\d{10,}$/; // Định dạng: ít nhất 10 chữ số
  
    return phonePattern.test(input);
  }
  

export const handlePhoneCall = value =>
{
    if(!isPhoneNumber( value ))
    {
        return
    }
    window.location.href = `tel:${value}`;
}

function isValidEmail(email) {
    // Biểu thức chính quy để kiểm tra định dạng email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    return emailPattern.test(email);
}
  

export const handleEmail = value =>
{
    if(!isValidEmail( value ))
    {
        return
    }
    const subject = 'Hello'; // Chủ đề email
    const mailtoURL = `mailto:${value}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoURL;
}



