// import alert from "../../helpers/alert";
import response from '@/configs/message_response'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _head from 'lodash/head'
import router from "@/router";
import HomeService from "@/services/home.service";
import fakeBackEnd from "@/helpers/fake-backend";
import { getToastError } from '@/helpers/toast';
const initialState = {
    contract_publish: 
    {
        old: null,
        new: null,
    },
    contract_budget:
    {
        old: null,
        new: null,
    },
    contract_budget_spend:
    {
        old: null,
        new: null,
    },
    contract_spend:
    {
        old: null,
        new: null,
    },
    contract_budget_remaining:
    {
        old: null,
        new: null,
    },

    service_free:
    {
        old: null,
        new: null,
    },

    service_progress: null,

    contract_notification_budget: null
};

export default {
    namespaced: true,
    state: initialState,
    actions:
    {
        getContractPublishNew: async({commit}, value) =>
        {
            var result
            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractPublish()
                commit('setContractPublishNew', _get(result, 'data.data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    localStorage.removeItem('jwt')      
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractPublishNew', _get(result, 'data.data'))
        },
        getContractPublishOld: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractPublish()
                commit('setContractPublishOld', _get(result, 'data.data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    localStorage.removeItem('jwt')  
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractPublishOld', _get(result, 'data.data'))
        },

        getContractBudgetNew: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractBudget()
                commit('setContractBudgetNew', _get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractBudgetNew', _get(result, 'data'))
        },
        getContractBudgetOld: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractBudget()
                commit('setContractBudgetOld', _get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractBudgetOld', _get(result, 'data'))
        },

        getContractSpendNew: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {   
                result = await fakeBackEnd.getContractSpend()
                commit('setContractBudgetSpendNew',_get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractBudgetSpendNew', _get(result, 'data'))
        },
        getContractSpendOld: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractSpend()
                commit('setContractBudgetSpendOld', _get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractBudgetSpendOld',  _get(result, 'data'))
        },


        getContractWeekSpendNew: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractSpend()
                commit('setContractSpendNew',_get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractSpendNew',  _get(result, 'data'))
        },
        getContractWeekSpendOld: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractSpend()
                commit('setContractSpendOld',_get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractSpendOld',_get(result, 'data'))
        },

        getContractBudgetRemainNew: async({commit}, value) =>
        {

            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractBudgetRemain()
                commit('setContractBudgetRemainingNew', _get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                //alert.getAlert(response, `public.${result.message}.message`, 'error')
                
                return 
            }
            commit('setContractBudgetRemainingNew', _get(result, 'data'))
        },
        getContractBudgetRemainOld: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractBudgetRemain()
                commit('setContractBudgetRemainingOld',_get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractBudgetRemainingOld',  _get(result, 'data'))
        },

        getServiceFreeNew: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getServiceFree()
                commit('setServiceFreeNew',_get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setServiceFreeNew', _get(result, 'data'))
        },
        getServiceFreeOld: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getServiceFree()
                commit('setServiceFreeOld',_get(result, 'data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setServiceFreeOld', _get(result, 'data'))
        },

        getServiceProgress: async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getServiceProgress()
                commit('setServiceProgress', _get(result, 'data.data'))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }
            
            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError(`public.${result.message}`, 2000)

                return 
            }
            commit('setServiceProgress', _get(result, 'data.data'))
        },

        getContractNotificationBudget:  async({commit}, value) =>
        {
            var result

            if(process.env.NODE_ENV === 'development')
            {
                result = await fakeBackEnd.getContractNotificationBudget()
                commit('setContractNotificationBudget',_head( _get(result, 'data.data')))
                return
            }

            try 
            {
                result = await HomeService.getContracReport(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2000)
                return 
            }

            if(_isEmpty(result))
            {
            
                
                return 
            }

            if(_get(result, 'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    
                    localStorage.removeItem('jwt')        
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2000)
                
                return 
            }
            commit('setContractNotificationBudget', _head(_get(result, 'data.data')))
        },
    },
    
    mutations: 
    {
        setContractPublishNew: (state, value) =>
        {
            state.contract_publish.new = value
        },
        setContractPublishOld: (state, value) =>
        {
            state.contract_publish.old = value
        },
        setContractBudgetNew: (state, value) =>
        {
            state.contract_budget.new = value
        },
        setContractBudgetOld: (state, value) =>
        {
            state.contract_budget.old = value
        },
        setContractBudgetSpendNew: (state, value) =>
        {
            state.contract_budget_spend.new = value
        },
        setContractBudgetSpendOld: (state, value) =>
        {
            state.contract_budget_spend.old = value
        },
        setContractSpendNew: (state, value) =>
        {
            state.contract_spend.new = value
        },
        setContractSpendOld: (state, value) =>
        {
            state.contract_spend.old = value
        },
        setContractBudgetRemainingNew: (state, value) =>
        {
            state.contract_budget_remaining.new = value
        },
        setContractBudgetRemainingOld: (state, value) =>
        {
            state.contract_budget_remaining.old = value
        },



        setServiceFreeNew: (state, value) =>
        {
            state.service_free.new = value
        },

        setServiceFreeOld: (state, value) =>
        {
            state.service_free.old = value
        },

        
        setServiceProgress: (state, value) =>
        {
            state.service_progress = value
        },

        setContractNotificationBudget: (state, value) =>
        {
            state.contract_notification_budget = value
        },
    }
}
