<script>
import {
    SimpleBar
} from "simplebar-vue3";

import i18n from "../i18n";
import {
	profileComputed,
    layoutComputed
} from "@/state/helpers";

import { profileMethods, layoutMethods } from "../state/helpers";
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { handleUpdatingValue } from "../configs/utils";
import { getFirstCharacterOfLastWord, isNoAvatar } from "../helpers/avatar";

/**
 * Nav-bar Component
 */
export default {
    data() {
        return {
            languages: [{
                flag: require("@/assets/images/flags/us.svg"),
                language: "en",
                title: "English",
            },
            {
                flag: require("@/assets/images/flags/vn.svg"),
                language: "vi",
                title: "Việt Nam",
            },
            ],
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
            myVar: 1,
            isEmpty,
            get,
            loading_profile: false,
            handleUpdatingValue,
            getFirstCharacterOfLastWord,
            isNoAvatar,
        };
    },
    components: {
        SimpleBar
    },

    methods: {
        ...profileMethods,
        ...layoutMethods,
        isCustomDropdown() {
            //Search bar
            var searchOptions = document.getElementById("search-close-options");
            var dropdown = document.getElementById("search-dropdown");
            var searchInput = document.getElementById("search-options");

            searchInput && searchInput.addEventListener("focus", () => {
                var inputLength = searchInput.value.length;
                if (inputLength > 0) {
                    dropdown.classList.add("show");
                    searchOptions.classList.remove("d-none");
                } else {
                    dropdown.classList.remove("show");
                    searchOptions.classList.add("d-none");
                }
            });

            searchInput && searchInput.addEventListener("keyup", () => {
                var inputLength = searchInput.value.length;
                if (inputLength > 0) {
                    dropdown.classList.add("show");
                    searchOptions.classList.remove("d-none");
                } else {
                    dropdown.classList.remove("show");
                    searchOptions.classList.add("d-none");
                }
            });

            searchOptions && searchOptions.addEventListener("click", () => {
                searchInput.value = "";
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            });

            document.body.addEventListener("click", (e) => {
                if (e.target.getAttribute("id") !== "search-options") {
                    dropdown.classList.remove("show");
                    searchOptions.classList.add("d-none");
                }
            });
        },

        toggleHamburgerMenu() {
            var windowSize = document.documentElement.clientWidth;
            let layoutType = document.documentElement.getAttribute("data-layout");
            let visiblilityType = document.documentElement.getAttribute("data-sidebar-visibility");

            document.documentElement.setAttribute("data-sidebar-visibility", "show");

            if (windowSize > 767)
                document.querySelector(".hamburger-icon").classList.toggle("open");

            //For collapse horizontal menu
            if (
                document.documentElement.getAttribute("data-layout") === "horizontal"
            ) {
                document.body.classList.contains("menu") ?
                    document.body.classList.remove("menu") :
                    document.body.classList.add("menu");
            }

            //For collapse vertical menu
            if (visiblilityType === "show" && (layoutType === "vertical" || layoutType === "semibox")) {
                if (windowSize < 1025 && windowSize > 767) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
                        document.documentElement.setAttribute("data-sidebar-size", "") :
                        document.documentElement.setAttribute("data-sidebar-size", "sm");
                } else if (windowSize > 1025) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
                        document.documentElement.setAttribute("data-sidebar-size", "sm") :
                        document.documentElement.setAttribute("data-sidebar-size", "lg");
                } else if (windowSize <= 767) {
                    document.body.classList.add("vertical-sidebar-enable");
                    document.documentElement.setAttribute("data-sidebar-size", "lg");
                }
            }

            //Two column menu
            if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
                document.body.classList.contains("twocolumn-panel") ?
                    document.body.classList.remove("twocolumn-panel") :
                    document.body.classList.add("twocolumn-panel");
            }
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        setLanguage(locale, country, flag) {
            localStorage.setItem('locale', locale);
            this.flag = flag;
            this.lan = locale;
            this.text = country;
            document.getElementById("header-lang-img").setAttribute("src", flag);
            i18n.global.locale = locale;

            window.location.reload()
        },
        toggleDarkMode() {
            if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
                document.documentElement.setAttribute("data-layout-mode", "light");
            } else {
                document.documentElement.setAttribute("data-layout-mode", "dark");
            }
        },
        initTopbarComponents() {
            function updateCartPrice() {
                var currencySign = "$";
                var subtotal = 0;
                Array.from(document.getElementsByClassName("cart-item-price")).forEach(function (e) {
                    subtotal += parseFloat(e.innerHTML);
                });
                if (document.getElementById("cart-item-total")) {
                    document.getElementById("cart-item-total").innerHTML = currencySign + subtotal.toFixed(2);
                }
            }
            if (document.getElementsByClassName("dropdown-item-cart")) {
                var dropdownItemCart = document.querySelectorAll(".dropdown-item-cart").length;
                Array.from(document.querySelectorAll("#page-topbar .dropdown-menu-cart .remove-item-btn")).forEach(function (item) {
                    item.addEventListener("click", function () {
                        dropdownItemCart--;
                        this.closest(".dropdown-item-cart").remove();
                        Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
                            e.innerHTML = dropdownItemCart;
                        });
                        updateCartPrice();
                        if (document.getElementById("empty-cart")) {
                            document.getElementById("empty-cart").style.display = dropdownItemCart == 0 ? "block" : "none";
                        }
                        if (document.getElementById("checkout-elem")) {
                            document.getElementById("checkout-elem").style.display = dropdownItemCart == 0 ? "none" : "block";
                        }
                    });
                });
                Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
                    e.innerHTML = dropdownItemCart;
                });
                if (document.getElementById("empty-cart")) {
                    document.getElementById("empty-cart").style.display = "none";
                }
                if (document.getElementById("checkout-elem")) {
                    document.getElementById("checkout-elem").style.display = "block";
                }
                updateCartPrice();
            }

            // notification messages
            if (document.getElementsByClassName("notification-check")) {
                Array.from(document.querySelectorAll(".notification-check input")).forEach(function (element) {
                    element.addEventListener("click", function (el) {
                        el.target.closest(".notification-item").classList.toggle("active");
                    });
                });
            }


        },

        getisEmpty(value)
        {
            if(isEmpty(value))
            {
                return 't-updating'
            }
            return value
        },

        getLanguage() 
        {
            let locale = localStorage.getItem('locale')
            
            if(isEmpty(locale))
            {
                locale = 'en'
                localStorage.setItem('locale', locale)
            }

            this.languages.forEach((item) => {
                if (item.language == locale) 
                {
                    this.lan  = locale;
                    this.text = item.country;
                    this.flag = item.flag;
                    document.getElementById("header-lang-img").setAttribute("src", item.flag);
                    i18n.global.locale = locale;
                }
            })
        }
    },
    computed: {
        ...profileComputed,
        ...layoutComputed,
    },
    mounted() {

        this.getLanguage()
        const getProfile = async() =>
        {
            if(isEmpty(this.profile))
            {
                this.loading_profile = true
                await this.getProfile()
                this.loading_profile = false
            }
        }
        getProfile()
        // if (process.env.VUE_APP_I18N_LOCALE) {
        //     this.flag = process.env.VUE_APP_I18N_LOCALE;
        //     this.languages.forEach((item) => {
        //         if (item.language == this.flag) {

        //             document.getElementById("header-lang-img").setAttribute("src", item.flag);
        //         }
        //     });
        // }
        document.addEventListener("scroll", function () {
            var pageTopbar = document.getElementById("page-topbar");
            if (pageTopbar) {
                document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
                    "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
            }
        });
        if (document.getElementById("topnav-hamburger-icon"))
            document
                .getElementById("topnav-hamburger-icon")
                .addEventListener("click", this.toggleHamburgerMenu);

        this.isCustomDropdown();
        this.initTopbarComponents();
    },
};
</script>

<template>
    <header id="page-topbar">
        <div class="layout-width">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box horizontal-logo">
                        <router-link to="/" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logo-dark.png" alt="" height="17" />
                            </span>
                        </router-link>

                        <router-link to="/" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logo-light.png" alt="" height="17" />
                            </span>
                        </router-link>
                    </div>

                    <!-- App Search-->
                    <form class="app-search d-none d-md-block">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                                id="search-options" value="" />
                            <span class="mdi mdi-magnify search-widget-icon"></span>
                            <span class="
                          mdi mdi-close-circle
                          search-widget-icon search-widget-icon-close
                          d-none
                        " id="search-close-options"></span>
                        </div>
                        <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                            <SimpleBar data-simplebar style="max-height: 320px">
                                <div class="dropdown-header">
                                    <h6 class="text-overflow text-muted mb-0 text-uppercase">
                                        Recent Searches
                                    </h6>
                                </div>

                                <div class="dropdown-item bg-transparent text-wrap">
                                    <router-link to="/" class="btn btn-soft-secondary btn-sm btn-rounded">how to setup <i
                                            class="mdi mdi-magnify ms-1"></i></router-link>
                                    <router-link to="/" class="btn btn-soft-secondary btn-sm btn-rounded">buttons <i
                                            class="mdi mdi-magnify ms-1"></i></router-link>
                                </div>
                                <div class="dropdown-header mt-2">
                                    <h6 class="text-overflow text-muted mb-1 text-uppercase">
                                        Pages
                                    </h6>
                                </div>

                                <b-link  class="dropdown-item notify-item">
                                    <i class="
                              ri-bubble-chart-line
                              align-middle
                              fs-18
                              text-muted
                              me-2
                            "></i>
                                    <span>Analytics Dashboard</span>
                                </b-link>

                                <b-link  class="dropdown-item notify-item">
                                    <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                                    <span>Help Center</span>
                                </b-link>

                                <b-link  class="dropdown-item notify-item">
                                    <i class="
                              ri-user-settings-line
                              align-middle
                              fs-18
                              text-muted
                              me-2
                            "></i>
                                    <span>My account settings</span>
                                </b-link>

                                <div class="dropdown-header mt-2">
                                    <h6 class="text-overflow text-muted mb-2 text-uppercase">
                                        Members
                                    </h6>
                                </div>

                                <div class="notification-list">
                                    <b-link  class="d-flex dropdown-item notify-item py-2">
                                        <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs"
                                            alt="user-pic" />
                                        <div class="flex-1">
                                            <h6 class="m-0">Angela Bernier</h6>
                                            <span class="fs-11 mb-0 text-muted">Manager</span>
                                        </div>
                                    </b-link>
                                    <b-link  class="d-flex dropdown-item notify-item py-2">
                                        <img src="@/assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs"
                                            alt="user-pic" />
                                        <div class="flex-1">
                                            <h6 class="m-0">David Grasso</h6>
                                            <span class="fs-11 mb-0 text-muted">Web Designer</span>
                                        </div>
                                    </b-link>
                                    <b-link  class="d-flex dropdown-item notify-item py-2">
                                        <img src="@/assets/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs"
                                            alt="user-pic" />
                                        <div class="flex-1">
                                            <h6 class="m-0">Mike Bunch</h6>
                                            <span class="fs-11 mb-0 text-muted">React Developer</span>
                                        </div>
                                    </b-link>
                                </div>
                            </SimpleBar>

                            <div class="text-center pt-3 pb-1">
                                <!-- /pages/search-results -->
                                <router-link to="/" class="btn btn-primary btn-sm">View All Results <i
                                        class="ri-arrow-right-line ms-1"></i></router-link>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="d-flex align-items-center">
                    <div class="dropdown d-md-none topbar-head-dropdown header-item">
                        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="bx bx-search fs-22"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-search-dropdown">
                            <form class="p-3">
                                <div class="form-group m-0">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search ..."
                                            aria-label="Recipient's username" />
                                        <b-button variant="primary" type="submit">
                                            <i class="mdi mdi-magnify"></i>
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="dropdown ms-1 topbar-head-dropdown header-item">
                        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img id="header-lang-img" ref="refheader" src="@/assets/images/flags/us.svg"
                                alt="Header Language" height="20" class="rounded" />
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <b-link  v-for="(entry, i) in languages" :key="`Lang${i}`"
                                :value="entry" @click="setLanguage(entry.language, entry.title, entry.flag)"
                                :class="{ active: lan === entry.language }" class="dropdown-item notify-item language py-2"
                                data-lang="en" :title="entry.title">
                                <img :src="entry.flag" alt="user-image" class="me-2 rounded" height="18" />
                                <span class="align-middle">{{ entry.title }}</span>
                            </b-link>
                        </div>
                    </div>

                    <div class="dropdown ms-sm-3 header-item topbar-user">
                        <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span class="d-flex align-items-center">
                                <div class="avatar-xs placeholder-glow">
                                    <div class="avatar-title bg-light rounded-circle border bg-white"
                                        v-if="!loading_profile && !isEmpty(profile)"
                                    >
                                        <img alt="" class="avatar-xs" id="imageid"
                                            v-if="!isNoAvatar(get(profile,'avatar'))"
                                            :src="get(profile,'avatar')"
                                        />
                                        <div class="text-info fs-4"
                                            v-else
                                        >
                                            {{ getFirstCharacterOfLastWord( get(profile,'display_name') ) }}
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                        class="placeholder avatar-title bg-light rounded-circle"
                                    >
    
                                    </div>
                                </div>
                                <span class="text-start ms-xl-2">
                                    <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text placeholder-glow">
                                        <span
                                            v-if="!loading_profile"
                                        >
                                            {{ get(profile,'display_name') || '(Dữ liệu đang được cập nhật...)' }}
                                        </span>
                                        <span class="placeholder rounded" style="width: 100px; height: 20px; margin-bottom: 4px;"
                                            v-else
                                        >
                                            </span>
                                    </span>
                                    <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text placeholder-glow">
                                        <span
                                            :class="{
                                                'placeholder rounded': !profile,
                                                'text-muted italic': handleUpdatingValue(get(profile,'job_title'))
                                            }"
                                        > 
                                            {{$t(getisEmpty(get(profile,'job_title')))}}
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <h6 class="dropdown-header">{{ $t("t-hello")  }} {{get(profile,'display_name') || '(Dữ liệu đang được cập nhật...)'}}!</h6>
                            <!-- /pages/profile -->
                            <router-link class="dropdown-item" to="/profile"><i
                                    class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle">{{$t('t-personal-information')}}</span>
                            </router-link>
                            <b-link class="dropdown-item" href="/logout"><i
                                    class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle" data-key="t-logout">{{$t('t-logout')}}</span>
                            </b-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template> 