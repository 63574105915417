<script>
import i18n from "@/i18n";

export default {
    data() {
        return {
            languages: [{
                flag: require("@/assets/images/flags/us.svg"),
                language: "en",
                title: "English",
            },
            {
                flag: require("@/assets/images/flags/vn.svg"),
                language: "vi",
                title: "Việt Nam",
            }],
            lan: i18n.locale,
            text: null,
            flag: null,
            itemActive: null,
        }
    },

    mounted() {
        this.getLanguage();
    },

    methods: {
        getLanguage() 
        {
            let locale = localStorage.getItem('locale') || 'en';

            this.languages.forEach((item) => {
                if (item.language == locale) 
                {
                    this.lan  = locale;
                    this.text = item.country;
                    this.flag = item.flag;
                    i18n.global.locale = locale;
                }
            })
        },

        setLanguage(locale, country, flag) {
            this.flag = flag;
            localStorage.setItem('locale', locale);
            this.lan = locale;
            this.text = country;
            this.itemActive = locale;
            i18n.global.locale = locale;
        },
    }
}
</script>


<template>
    <div class="auth-page-wrapper pt-2 position-fixed top-0 end-0 start-0 bottom-0 d-flex align-items-center jusrify-content-center">
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>
            <div class="shape">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
        </div>
        <div class="root-container" style="z-index: 10;">
            <div class="d-flex flex-column align-items-center">
                <div class="text-center d-flex flex-column align-items-center">
                    <div class="justify-content-center list-logo-size">
                        <div style="z-index: 10 !important;">
                            <img src="../../public/images/adsplus-google-premier-partner.png" alt="logo_login"
                                style="width: 100%; object-fit: cover;">
                        </div>
                    </div>
                </div>
                <p class="my-1 fw-medium text-size text-nowrap text-white-50 ">
                    {{ $t('t-real-time-advertising-data-reporting-platform-for-customers') }}
                  </p>
                <slot></slot>
                <div class="mt-2">
                    <a href="https://adsplus.vn/chinh-sach-bao-mat-thong-tin-adsplus/" target="_blank" class="link-primary">
                        {{ $t('t-privacy-policy') }}
                    </a>
                </div>
                <div class="mt-4 d-flex" style="gap: 8px">
                    <b-link  v-for="(entry, i) in languages" :key="`Lang${i}`"
                        :value="entry" @click="setLanguage(entry.language, entry.title, entry.flag)"
                        :class="{ itemActive: lan === entry.language,}" class="notify-item language"
                        data-lang="en" :title="entry.title"
                    style=" padding: 4px 8px !important; display: flex; justify-content: center;align-items: center;"
                    >
                        <img :src="entry.flag" alt="user-image" class="rounded" height="18" />
                    </b-link>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.itemActive {
    border-radius: 4px;
    
    border: 1px solid red;
   
}
.auth-page-wrapper{
    padding-bottom: 30px;
}

.root-container{
    width: 400px;
    margin: 0 auto;
}


@media (max-width: 1560px) {
    .root-container{
        width: 300px
    }
}


</style>