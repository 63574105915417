
import fetch from "../helpers/fetch";
import fakeBackEnd from "../helpers/fake-backend";
import { 
    API_CONTRACT_RECEIPTS_STATISTIC,
    API_CONTRACT_STATISTIC, 
    API_CONTRACT_DETAIL, 
    API_REPORT_CONTRACT,
    API_CONTRACT_RECEIPTS,
    API_CONTRACTS,
    API_CONTRACTS_EVALUTE_RATING,
    API_REPORTS_PERFORMANCE,
    API_CONTRACT_LATEST,
    API_CREATE_COMMENT,
    API_REPLY_COMMENT,
    API_GET_COMMENTS,
    API_DELETE_COMMENT,
    API_EDIT_COMMENT,
    API_REPORT_CONTRACT_BY_DAY,
    API_REPORT_CONTRACT_BY_WEEK,
    API_REPORT_CONTRACT_BY_MONTH,
    API_REPORT_CONTRACT_BY_QUARTER,
    API_REPORT_CONTRACT_BY_YEAR,
    API_REPORT_CONTRACT_STATISTICAL_BY_DAY,
    API_REPORT_CONTRACT_STATISTICAL_BY_WEEK,
    API_REPORT_CONTRACT_STATISTICAL_BY_MONTH,
    API_REPORT_CONTRACT_STATISTICAL_BY_QUARTER,
    API_REPORT_CONTRACT_STATISTICAL_BY_YEAR,
    API_REPORT_CONTRACT_SUMMARY_BY_DAY,
    API_REPORT_CONTRACT_SUMMARY_BY_WEEK,
    API_REPORT_CONTRACT_SUMMARY_BY_MONTH,
    API_REPORT_CONTRACT_SUMMARY_BY_QUARTER,
    API_REPORT_CONTRACT_SUMMARY_BY_YEAR,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_ACCOUNT,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_CAMPAIGN,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_GROUP,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_GENDER,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_DEVICE,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_REGION,
    API_CUSTOMER_REPORT_PERFORMANCE_AD,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_KEYWORD,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_AGE_RANGE
} from "../configs/api";
import { fakeDataComment } from "../components/apps/contract/contractDetail/feedback/comment/fakeData";



class ContractService
{
    static async getReceiptsStatisticById( )
    {
        const result = await fetch.make().get(API_CONTRACT_RECEIPTS_STATISTIC, {authHeader: true})
        return result
    }


    static async getReceiptsById(query)
    {
        const result = await fetch.make().get(API_CONTRACT_RECEIPTS, {query: query, authHeader: true})
        return result
    }
    

    static async getContracts(value)
    {
        const result = await fetch.make().get(API_CONTRACTS,{
            authHeader: true,
            query: value.query
        })
        return result
    }

    static async getContractEvaluteRating(value)
    {
        if(process.env.NODE_ENV == 'development')
        {
            const result = await fakeBackEnd.getContractEvaluteRating(value)
            return result
        }
        var URL_CONTRACTS_EVALUTE_RATING = String(API_CONTRACTS_EVALUTE_RATING).replace("/:id", "/"+value.id.id)
        
        const result = await fetch.make().put(URL_CONTRACTS_EVALUTE_RATING, value, {authHeader: true})
        return result
    }

    static async getContractsStatistic()
    {
        try 
        {
            const result = await fetch.make().get(API_CONTRACT_STATISTIC ,{ authHeader: true})
            return result
        } 
        catch (error) 
        {
            throw new Error(error)
        }
    
    }


    static async getContractDetail(id)
    {

        var URL_CONTRACT_DETAIL = String(API_CONTRACT_DETAIL).replace("/:id","")

        if (process.env.NODE_ENV == "development")
        {
            const result = await fakeBackEnd.getContractDetail(URL_CONTRACT_DETAIL + `/${id}`)
            return result
        }

        const result = await fetch.make().get(URL_CONTRACT_DETAIL + `/${id}` ,{ authHeader: true})

        return result
        
        
    }

    static async getReports(value)
    {
        const result = await fetch.make().get(API_REPORT_CONTRACT, 
            { 
                authHeader: true,
                query: value.query
            })

        return result

    }

    static async getPerformance(value)
    {
        const result = await fetch.make().get(API_REPORTS_PERFORMANCE, 
            { 
                authHeader: true,
                query: value.query
            })

        return result
    }

    static async getContractLatest()
    {
        const result = await fetch.make().get(API_CONTRACT_LATEST, {authHeader: true})
        return result
    }

    static async getActivitiesLatestByContractID(query, id)
    {
        const URL_CONTRACT_DETAIL = String(API_CONTRACT_DETAIL).replace("/:id","")
        const result = await fetch.make().get(URL_CONTRACT_DETAIL + `/${id}/activity` ,
        { 
            authHeader: true,
            query: query
        })

        return result
    }

    static async createComment(comment, contract_id, test)
    {

        var URL_CREATE_COMMENT = String(API_CREATE_COMMENT).replace("/:id", "/"+contract_id)
        
       if(!test)
       {           
           const result = await fetch.make().post(URL_CREATE_COMMENT, 
           {
               "content": comment
           }, 
           {authHeader: true}
           )
           return result
       }
       
       return true

    }

    static async replyComment(comment, contract_id, parent_id, test)
    {

        var URL_TEMP = String(API_REPLY_COMMENT).replace("/:id", "/"+contract_id)
        var URL_REPLY_COMMENT = String(URL_TEMP).replace("/:parent_id", "/"+parent_id)
        
        if (!test)
        {
              const result = await fetch.make().post(URL_REPLY_COMMENT, 
            {
                "content": comment
            }, 
            {authHeader: true}
            )
            return result
        }
        return true
    }

    static async editComment(comment, contract_id, comment_id, test)
    {

        var URL_TEMP = String(API_EDIT_COMMENT).replace("/:id", "/"+contract_id)
        var URL_EDIT_COMMENT = String(URL_TEMP).replace("/:comment_id", "/"+comment_id)
        
        // console.log(URL_EDIT_COMMENT)
        if (!test)
        {
              const result = await fetch.make().put(URL_EDIT_COMMENT, 
            {
                "content": comment
            }, 
            {authHeader: true}
            )
            return result
        }
        return true
    }

    static async getComments(contract_id, pagination ,mode)
    {

        var URL_GET_COMMENTS = String(API_GET_COMMENTS).replace("/:id", "/"+contract_id)
     
        if(!mode)
        {
            const result = await fetch.make().get(URL_GET_COMMENTS, 
                {
                    query: {pagination: pagination} ,
                    authHeader: true
                })
            return result
        }
        const data = await fakeDataComment()
        return data
        
    }

    static async deleteComment(contract_id, comment_id, mode)
    {

        var URL_TEMP = String(API_DELETE_COMMENT).replace("/:id", "/"+contract_id)
        var URL_REPLY_COMMENT = String(URL_TEMP).replace("/:comment_id", "/"+comment_id)
        // console.log(URL_REPLY_COMMENT)
        
        if(!mode)
        {
            const result = await fetch.make().delete(URL_REPLY_COMMENT, {authHeader: true})
            return result
        }

        return true
        
    }


    /**
     * Retrieves reports based on the provided value.
     *
     * @param {string} dimension - The value used to retrieve the reports.
     * @param {object} paramerters - The value used to retrieve the reports.
     * @return {object} The result of the fetch request.
     */
    static async getReportV2(dimension, paramerters)
    {
        let endpoint = API_REPORT_CONTRACT_STATISTICAL_BY_YEAR;
        
        switch(dimension)
        {
            case 'day':
                endpoint = API_REPORT_CONTRACT_BY_DAY;
                break;
            case 'week':
                endpoint = API_REPORT_CONTRACT_BY_WEEK;
                break;
            case 'month':
                endpoint = API_REPORT_CONTRACT_BY_MONTH;
                break;
            case 'quarter':
                endpoint = API_REPORT_CONTRACT_BY_QUARTER;
                break;
            case 'year':
                endpoint = API_REPORT_CONTRACT_BY_YEAR;
                break;
            default:
                break;
        }

        const result = await fetch.make().get(endpoint, 
            { 
                authHeader: true,
                query: paramerters
            })

        return result
    }

    
    /**
     * Retrieves the report statistic based on the specified dimension and parameters.
     *
     * @param {string} dimension - The dimension for the report statistic ('day', 'week', 'month', 'quarter', 'year').
     * @param {object} parameters - The parameters for the report statistic.
     * @return {Promise} A promise that resolves to the report statistic.
     */
    static async getReportStatisticV2(dimension, paramerters)
    {
        let endpoint = API_REPORT_CONTRACT_STATISTICAL_BY_YEAR;
        
        switch(dimension)
        {
            case 'day':
                endpoint = API_REPORT_CONTRACT_STATISTICAL_BY_DAY;
                break;
            case 'week':
                endpoint = API_REPORT_CONTRACT_STATISTICAL_BY_WEEK;
                break;
            case 'month':
                endpoint = API_REPORT_CONTRACT_STATISTICAL_BY_MONTH;
                break;
            case 'quarter':
                endpoint = API_REPORT_CONTRACT_STATISTICAL_BY_QUARTER;
                break;
            case 'year':
                endpoint = API_REPORT_CONTRACT_STATISTICAL_BY_YEAR;
                break;
            default:
                break;
        }

        const result = await fetch.make().get(endpoint, 
            { 
                authHeader: true,
                query: paramerters
            })

        return result
    }

    
    /**
     * Retrieves the report summary based on the specified dimension and parameters.
     *
     * @param {string} dimension - The dimension of the report (day, week, month, quarter, year).
     * @param {object} parameters - The parameters for the report.
     * @return {Promise<object>} - The report summary.
     */
    static async getReportSummaryV2(dimension, paramerters)
    {
        let endpoint = API_REPORT_CONTRACT_SUMMARY_BY_YEAR;
        
        switch(dimension)
        {
            case 'day':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_DAY;
                break;
            case 'week':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_WEEK;
                break;
            case 'month':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_MONTH;
                break;
            case 'quarter':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_QUARTER;
                break;
            case 'year':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_YEAR;
                break;
            default:
                break;
        }

        const result = await fetch.make().get(endpoint, 
            { 
                authHeader: true,
                query: paramerters
            })

        return result
    }

    static async getReportPerformanceGroup(value, mode = 'ad_account')
    {
        let temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_ACCOUNT

        switch(mode)
        {
            case 'ad':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD;
                break;
            case 'ad_account':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_ACCOUNT;
                break;
            case 'ad_group':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_GROUP;
                break;
            case 'ad_campaign':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_CAMPAIGN;
                break;
            case 'ad_gender':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_GENDER;
                break;
            case 'ad_device':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_DEVICE;
                break;
            case 'ad_region':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_REGION;
                break;
            case 'ad_age_range':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_AGE_RANGE;
                break;
            case 'ad_keyword':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_KEYWORD;
                break;
         
            default:
                break;
        }

        const result = await fetch.make().get(temp_api,
        {
            authHeader: true,
            query: value.query
        })
        return result
    }
}


export default ContractService