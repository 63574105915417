
import { API_FOLLOW_SERVICE, API_PROFILE, API_PROFILE_SUPPORT, API_PROFILE_UPDATE, API_UN_FOLLOW_SERVICE, API_UPDATE_PASSWORD } from "../configs/api";


import fetch from "../helpers/fetch";


class ProfileService {
    
    static async getProfile()
    {
        const result = await fetch.make().get(API_PROFILE,{ authHeader: true })
        return result
    }

    static async getUpdateProfile(value)
    {
        const result = await fetch.make().put(API_PROFILE_UPDATE,value, {authHeader: true})
        return result
    }

    static async getFollowService(value)
    {
        const result = await fetch.make().post(API_FOLLOW_SERVICE,value, {authHeader: true})
        return result
    }
    
    static async getUnFollowService(value)
    {
        const result = await fetch.make().post(API_UN_FOLLOW_SERVICE,value, {authHeader: true})
        return result
    }
    
    static async updatePassword(value)
    {
        const result = await fetch.make().put(API_UPDATE_PASSWORD, value,  {authHeader: true})
        return result
    }

    static async getSupporterContact()
    {
        const result = await fetch.make().get(API_PROFILE_SUPPORT, {authHeader: true})
        return result
    }
}

export default ProfileService