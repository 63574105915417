export default {
    SUCCESS: {
        message: "Thành công",
        auth: false,
    },
    INVALID_INPUT: {
        message: "Thành công",
        auth: false,
    },
    NO_CONTENT: {
        message: "Không tìm thấy dữ liệu",
        auth: false,
    },
};
