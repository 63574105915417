
import admin_response from "./message_response/admin.js";
const response = {
    public: {
        SUCCESS: {
            message_vi: 'Thành công',
            message_en: 'Success',
            auth: false
        },
        INVALID_INPUT: {
            message: 'Giá trị truyền vào không hợp lệ',
            auth: false
        },
        UNAUTHORIZED: {
            message: 'Không có quyền thực hiện',
            auth: true
        },
        NOT_AVAILABLE: {
            message: 'Tài khoảng không hoạt động',
            auth: true
        },
        NOT_FOUND: {
            message: 'Không tìm thấy',
            auth: false
        },
        FORBIDDEN: {
            message: 'Bạn không được phép làm điều này',
            auth: false
        },
        SERVER: {
            message_en: 'Server maintenance',
            message_vi: 'Server bảo trì',
            auth: false
        },
        CONFLICT: {
            message: 'Tính năng đang bảo trì',
            auth: false   
        },
        NO_CONTENT: {
            message: 'Không có dữ liệu phù hợp',
            auth: false   
        }
    },
    comment: {
        SUCCESS: {
            message: 'Thành công',
            auth: false
        },
        INVALID_INPUT: {
            message: 'Bình luận này không tồn tại nữa',
            auth: false
        },
        UNAUTHORIZED: {
            message: 'Không có quyền thực hiện',
            auth: true
        },
        NOT_AVAILABLE: {
            message: 'Tài khoảng không hoạt động',
            auth: true
        },
        NOT_FOUND: {
            message: 'Không tìm thấy',
            auth: true
        },
        FORBIDDEN: {
            message: 'Bạn không được phép làm điều này',
            auth: true
        },
        SERVER: {
            message: 'Server bảo trì',
            auth: true
        },
        CONFLICT: {
            message: 'Tính năng đang bảo trì',
            auth: true   
        },
        NO_CONTENT: {
            message: 'Không có dữ liệu phù hợp',
            auth: true   
        }
    },
    change_password: {
        SUCCESS: {
            message_en: 'Your password has been changed',
            message_vi: 'Mật khẩu của bạn đã được thay đổi',
            auth: false
        },
        INVALID_INPUT: {
            message_en: 'Invalid input value',
            message_vi: 'Giá trị truyền vào không hợp lệ',
            auth: false
        },
        UNAUTHORIZED: {
            message_en: 'Không có quyền thực hiện',
            message_vi: 'No permission to perform this action',
            auth: true
        },
        NOT_AVAILABLE: {
            message_en: 'Account is inactive',
            message_vi: 'Tài khoản không hoạt động',
            auth: true
        },
        NOT_FOUND: {
            message_en: 'Incorrect old password',
            message_vi: 'Mật khẩu cũ không đúng',
            auth: false
        },
        FORBIDDEN: {
            message_vi: 'You are not allowed to do this',
            message_en: 'Bạn không được phép làm điều này',
            auth: true
        },
        SERVER: {
            message_en: 'Server maintenance',
            message_vi: 'Server bảo trì',
            auth: false
        },
        CONFLICT: {
            message_en: 'Tính năng đang bảo trì',
            message_vi: 'Tính năng đang bảo trì',
            auth: true   
        },
        NO_CONTENT: {
            message_en: 'Không có dữ liệu phù hợp',
            message_vi: 'Feature under maintenance',
            auth: true   
        }
    },
    login_user_pwd: {
        SUCCESS: {
            message: 'Đăng nhập thành công',
            auth: false
        },
        INVALID_INPUT: {
            message: 'Tài khoản và mật khẩu không hợp lệ',
            auth: false
        },
        UNAUTHORIZED: {
            message: 'Bạn không có quyền thực hiện',
            auth: true
        },
        NOT_AVAILABLE: {
            message: 'Tài khoản không tồn tại',
            auth: true
        },
        NOT_FOUND: {
            message: 'Tài khoản không tồn tại',
            auth: true
        },
        FORBIDDEN: {
            message: 'Tài khoản bị khóa',
            auth: true
        },
        SERVER: {
            message: 'Server đang bảo trì',
            auth: false,
        },
        WRONG_LOGIN_INFORMATION: {
            message: 'Tài khoản và mật khẩu không hợp lệ',
            auth: true
        }
    },

    permission: {
        SUCCESS: {
            message: 'Láy phân quyền thành công',
            auth: false
        },
        INVALID_INPUT: {
            message: 'Token không hợp lệ',
            auth: true
        },
        UNAUTHORIZED: {
            message: 'Bạn không có quyền thực hiện',
            auth: true
        },
        NOT_AVAILABLE: {
            message: 'Tài khoản không hoạt động',
            auth: true
        },
        NOT_FOUND: {
            message: 'Không tìm thấy tài khoản của bạn',
            auth: true
        },
        FORBIDDEN: {
            message: 'Bạn không có quyền truy cập và hệ thống',
            auth: true
        },
        FIREBASE_EXCEPTION: {
            message: 'Thông tin đăng nhập không hợp lệ',
            auth: true
        }
    },

    firebase: {
        SUCCESS: {
            message: 'Đăng nhập thành công',
            auth: false
        },
        INVALID_INPUT: {
            message: 'Tài khoản không hợp lệ',
            auth: true
        },
        UNAUTHORIZED: {
            message: 'Bạn không có quyền thực hiện',
            auth: true
        },
        NOT_AVAILABLE: {
            message: 'Tài khoản không hoạt động',
            auth: true
        },
        NOT_FOUND: {
            message: 'Tài khoản của bạn không có trong hệ thống của chúng tôi',
            auth: true
        },
        FORBIDDEN: {
            message: 'Bạn không có quyền truy cập và hệ thống',
            auth: true
        },
        SERVER: {
            message: 'Có lỗi trong quá trình đăng nhập vui lòng thử lại',
            auth: true
        },
        FIREBASE_EXCEPTION: {
            message: 'Thông tin đăng nhập không hợp lệ',
            auth: true
        }
    },

    admin: admin_response
}


export default response