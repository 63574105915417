<script>
export default {
    data() {
        return {
            version: process.env.VUE_APP_VERSION
        }
    }
}
</script>

<template>
    <footer class="footer">
        <b-container fluid>
            <b-row>
                <b-col col sm="6">
                    <div class="d-flex align-items-center gap-2">
                        {{ new Date().getFullYear() }} © ADSPLUS
                        <a href="https://adsplus.vn/chinh-sach-bao-mat-thong-tin-adsplus/" target="_blank" class="link-primary">
                            {{ $t('t-privacy-policy') }}
                        </a>
                    </div>
                </b-col>
                <b-col col sm="6">
                    <div class="text-sm-end d-none d-sm-block">
                        <span class="fw-bold">Version: {{ version }}</span> &nbsp;|&nbsp;
                        Design & Develop by ADSPLUS
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>
