import ContractService from "../../services/contract.service";
// import router from "../../router";
import response from "../../configs/message_response"
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import router from "../../router";
import DashboardService from "../../services/dashboardService.service";
import { getToastError, getToastSuccess } from '../../helpers/toast';

const initialState = 
{
    contracts: [],
    contractsStatistic: [],
    contractDetail: [],
    routeDetail: null,
    isAuthDetail: true,

    detached: [],

    firstDateOfThisMonth: null,
    firstDateOfLastMonth: null,
    lastDateOfLastMonth: null,

    mainPerformaceValueThisTime: [],

    adPerforemaceValue: [],
    adPerforemaceValueLastTime: [],

    listReceiptById: [],


    receipts: [],
    recreips_statistic: [],


    chooseTime: "month", // dimension report default
    comment: {
        mode: "create",
        contract_id: "",
        parent_id: "",
        content:"",
        parent_name: "" 
    },

};

export default
{
    namespaced: true,
    state: initialState,
    actions: {

        setChooseTimeAction: ({commit}, payload)=>
        {
            commit('setChooseTimeMutation', payload)
        },

        getReceiptsStatisticById: async({commit}) =>
        {
            var result = null
            try 
            {
                result = await ContractService.getReceiptsStatisticById()
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2500)
                return
            }
            
            if(_isEmpty(result))
            {
                return
            }

            if(_get(result,'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2500)
                return
            }
            commit('setReceiptsStatistic',_get(result, 'data'))
        },
        
        getReceiptsById: async({commit}, value) =>
        {
            var result = null
            try 
            {
                result = await ContractService.getReceiptsById(value)
            } 
            catch (error) 
            {
                return
            }   
            
           
            if(_get(result,'status') == 'error')
            {
                if(_get(response, `public.${result.message}.auth`))
                {
                    router.push('/')
                    return
                }
                getToastError('public.SERVER', 2500)
               
                return
            }
            commit('setReceipts',_get(result, 'data'))
        },


        getContracts: async({commit},value) =>
        {
            var result = null
            try 
            {
                result = await ContractService.getContracts(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_isEmpty(result))
            {
                return
            }

            if(!_get(result,'status'))
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_get(result,'status') == 'error')
            {
               if( _get(response,`public.${result.message}.auth`))
               {
                    localStorage.removeItem('jwt')
                    router.push('/')
                    return
               }
                getToastError('public.SERVER', 2500)
                return
            }
            
            commit('setContracts',_get(result,'data'))
            return
        },

        getFirstDateOfThisMonth: ({ commit }, value) =>
        {
            commit('setFirstDateOfThisMonth', value)
        },

        getFirstDateOfLastMonth: ({ commit }, value) =>
        {
            commit('setFirstDateOfLastMonth', value)
        },

        getLastDateOfLastMonth: ({ commit }, value) =>
        {
            commit('setLastDateOfLastMonth', value)
        },


        getContractsStatistic: async ({ commit }) => 
        {
            const resultPromise = ContractService.getContractsStatistic()
        
            try 
            {
                const result = await resultPromise

            
                if (_isEmpty(result)) 
                {
                    return false
                }
            
                if (!_get(result, 'status')) 
                {
                    return false
                }
            
                if (_get(result, 'status') === 'error') 
                {
                    if (_get(response, `public.${result.message}.auth`)) 
                    {
                        router.push('/')
                        return false
                    }
                
                    return false
                }
            
                commit('setContractsStatistic', _get(result, 'data'))
                return true
            } 
            catch (error) 
            {
                if ( 408 === error.code ) 
                {
                    getToastError('Quá thời gian kết nối, vui lòng thử lại.', 2500, 2500)
                } 
                return false
            }
        },

        getContractDetail: async ({ commit }, value ) => 
        {
            try 
            {
               
                const result = await ContractService.getContractDetail(value)
                
                if (_isEmpty(result)) 
                {
                    return false
                }
                
                
                if (_get(result, 'status') === 'error') 
                {
                    
                    getToastError('Gặp lỗi trong quá trình lấy chi tiết hợp đồng', 2500 )
                    
                    return false
                }
                
                commit('setContractDetail', result.data)
            }
            catch (error)
            {
                if ( 408 === error.code ) 
                {
                    getToastError('Quá thời gian kết nối, vui lòng thử lại.', 2500, 2500)
                } 
                return false
            }

        },


        getContractEvaluteRating: async(context, value) =>
        {
            var result
            try 
            {
                result = await ContractService.getContractEvaluteRating(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2500)
                return
            }

           
            if( _isEmpty(result))       
            {
                getToastError('public.SERVER', 2500)
                return
            }
            
            if('error' == _get(result, 'status') )
            {
                if(_get(response, `public.${_get(result, 'message')}.auth`))
                {
                    getToastError(`public.${_get(result, 'message')}`, 2500)
                    // router.push('/')
                    return
                }

                getToastError(`public.${_get(result, 'message')}`, 2500)
                return
            }

            getToastSuccess('Chúng tôi đã nhận được đánh giá của bạn',2000)

        },

        // đã qua
        getDetached: async ({commit, state}, value) =>
        {
            try 
            { 
                
                const result = await ContractService.getReports(value)

                if (_isEmpty(result)) 
                {
                    return false
                }

                const data= result.data.data[0]
                commit('setDetached', data)

                if (!_get(result, 'status')) 
                {
                    return false
                }
            
                if (_get(result, 'status') === 'error') 
                {
                    if (_get(response, `public.${result.message}.auth`)) 
                    {
                        if (state.isAuthDetail)
                        {
                            commit('setIsAuthDetail', false)
                            return false
                        }

                    }
                    return false
                }
                

                
            }
            catch (error)
            {
                if ( 408 === error.code ) 
                {
                    getToastError('Quá thời gian kết nối, vui lòng thử lại.', 2500, 2500)
                } 
                return false
            }
        },



        
        
        getAdPerforemaceValue: async( { commit }, value) =>
        {
            var result = null
            try 
            {
                result = await DashboardService.getReportPerformance(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_isEmpty(result))
            {
                return
            }

            if(!_get(result,'status'))
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_get(result,'status') == 'error')
            {
               if( _get(response,`public.${result.message}.message`))
               {
                    getToastError('public.SERVER', 2500)
                    return
               }

                getToastError('public.SERVER', 2500)
                return
            }
            var data = result.data.data.sort((a,b) => new Date(a.date) - new Date(b.date) )

            if(value.mode == "lastTime")
            {
                commit('setAdPerforemaceValueLastTime', data )
                return
            }


            commit('setAdPerforemaceValue', data )
            return
  
        },

        setComment: ({commit}, payload)=>
        {
            commit('setCommentMutation', payload)
        }


    },

    mutations:
    {

        setChooseTimeMutation: (state, value) =>
        {
            state.chooseTime = value
        },

        setReceiptsStatistic: (state,value) =>
        {
            state.recreips_statistic = value
        },

        setContracts: (state, value) => 
        {
            state.contracts = value

        },

        setReceipts: (state, value) =>
        {
            state.receipts = value
        },

        setContractsStatistic: (state, value) => {
            state.contractsStatistic = value
        },

        setContractDetail: (state, value) => 
        {
            state.contractDetail = value
        },

        setIsAuthDetail: (state, value) =>
        {
            state.isAuthDetail = value

        },

        setAdPerforemaceValue: (state, value)=>
        {
            state.adPerforemaceValue = value
        },

        setAdPerforemaceValueLastTime: (state, value)=>
        {
            state.adPerforemaceValueLastTime = value
        },



        setDetached: (state, value)=>
        {
            state.detached = value
        },

        setCommentMutation: (state, value)=>
        {
            state.comment = {
                ...state.comment,
                ...value
            }
        }
    },
};

