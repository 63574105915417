import { getFireBase } from "../firebase";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import fetch from "../helpers/fetch"
import {
    API_FORGOT_PWD,
    API_RESET_PWD,
    API_PERMISSION,
    API_LOGIN_USER_PWD,
    API_LOGIN_FIREBASE,
    API_FORGOT_CID,
} from "../configs/api"


class AuthenticationService {
 

    static async forgotPassword(user_name)
    {
        const result = await fetch.make().post(API_FORGOT_PWD, user_name)
        return result            
    }

    static forgotCID(email)
    {
        const result = fetch.make().post(API_FORGOT_CID,email)
        return result;
    }

    static async resetPassword(value) {
        const result = await fetch.make().put(API_RESET_PWD,value)
        return result        
    }

    static async loginWithFireBase() {
        try {  
            getFireBase();
            const provider = new GoogleAuthProvider()
            const auth = getAuth();
            const result = await signInWithPopup(auth,provider)
            const credential = GoogleAuthProvider.credentialFromResult(result);

            const feedback = await fetch.make().post(API_LOGIN_FIREBASE, {
                idToken: credential.idToken
            });
            return feedback
        } catch (error) {
            return error
        }
    }
    
    static async login(user_name, password, remember_me) {
        const result = await fetch.make().post(API_LOGIN_USER_PWD,{user_name,password,remember_me})
        return result
    }

    static async getPermission()
    {
        const result = await fetch.make().get(API_PERMISSION,{authHeader: true})
        return result
    }

}
export default AuthenticationService;