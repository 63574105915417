import DashboardService from "../../services/dashboardService.service";
import response from "../../configs/message_response"

import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import fakeBackEnd from "../../helpers/fake-backend";
import ContractService from "../../services/contract.service";
import { getToastError } from '../../helpers/toast';

const initialState = {

    tableContractPublic: [],
    adPerforemaceValue: [],
    adPerforemaceValueLastTime: [],

    spend: [],
    spendEveryMonth: [],

    sliderThisTime:[],

    chooseService: "all", // service type report default
    chooseTime: "month", // dimension report default
    serviceList: [],
};

export default
{
    namespaced: true,
    state: initialState,
    actions: {
        setSliderAction: ({commit}, payload)=>
        {
            commit('setSliderMutation',payload)
        },

        getSpendEveryMonth: async( { commit }, value) =>
        {
            if ( process.env.NODE_ENV == "development")
            {
                if (value.mode == "lastTime")
                {
                    const result = fakeBackEnd.getBudgetAllocationRateLastMonth()
                    const data = result.data
                    commit('setBudgetAllocationRateLastTime', data )
                    return
                }

                const result = await fakeBackEnd.getBudgetAllocationRateThisMonth()
                const data = result.data
                commit('setSpendEveryMonth', data )
                return

            }
            var result = null
            try 
            {
                result = await DashboardService.getReportContractSpend(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_isEmpty(result))
            {
                return
            }

            if(!_get(result,'status'))
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_get(result,'status') ==  2500)
            {
               if( _get(response,`public.${result.message}.message`))
               {
                    getToastError('public.SERVER', 2500)
                    return
               }
                getToastError('public.SERVER', 2500)
                return
            }
            var data = result.data

            if ( value.mode == "lastTime")
            {
                commit('setSpendEveryMonth', data )
                return
            }

            commit('setSpendEveryMonth', data )
            return
        
        },

        getSpend: async( { commit }, value) =>
        {
            if ( process.env.NODE_ENV == "development")
            {
                const result = await fakeBackEnd.getPerformaceValue()
                const data = result.data
                commit('setSpend', data )
                return

            }
            var result = null
            try 
            {
                result = await DashboardService.getReportContract(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_isEmpty(result))
            {
                return
            }

            if(!_get(result,'status'))
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_get(result,'status') ==  2500)
            {
               if( _get(response,`public.${result.message}.message`))
               {
                    getToastError('public.SERVER', 2500)
                    return
               }

                getToastError('public.SERVER', 2500)
                return
            }

            var data = result.data
            commit('setSpend', data )
            return
  
        },



        getTableContractPublic: async( { commit }, value) =>
        {
            var result = null
            try 
            {
                result = await ContractService.getContracts(value)
            } 
            catch (error) 
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_isEmpty(result))
            {
                return
            }

            if(!_get(result,'status'))
            {
                getToastError('public.SERVER', 2500)
                return
            }

            if(_get(result,'status') ==  2500)
            {
               if( _get(response,`public.${result.message}.message`))
               {
                    getToastError('public.SERVER', 2500)
                    return
               }
                getToastError('public.SERVER', 2500)
                return
            }
            var data = result.data



            commit('setTableContractPublic', data )
            return
        
        },

        
        getAdPerforemaceValue: async( { commit }, value) =>
        {
            
            if ( value.mode == "lastTime")
            {
                commit('setAdPerforemaceValueLastTime', value.data )
                return
            }


            commit('setAdPerforemaceValue', value.data )
            return
  
        },



        setChooseTimeAction: ({commit}, payload)=>
        {
            commit('setChooseTimeMutation', payload)
        },

        setChooseServiceAction: ({commit}, payload)=>
        {
            if (!payload)
            {
                commit('setChooseServiceMutation', {})
                return
            }

            if (payload == 'all')
            {
                commit('setChooseServiceMutation', {})
                return 
            }

            var filter = [
                {
                    "name": "contract_type",
                    "operator": "=",
                    "value": payload
                }
            ]

            commit('setChooseServiceMutation', filter)
        },


        setServiceList: ({commit}, payload)=>{

            commit('setServiceListMutation', payload)
        }

    },


    mutations:
    {
        setChooseServiceMutation: (state, value) =>
        {
            state.chooseService = value
        },
        
        setChooseTimeMutation: (state, value) =>
        {
            state.chooseTime = value
        },



        setAdPerforemaceValue: (state, value)=>
        {
            state.adPerforemaceValue = value
        },

        setAdPerforemaceValueLastTime: (state, value)=>
        {
            state.adPerforemaceValueLastTime = value
        },


        setTableContractPublic: (state, value)=>
        {
            state.tableContractPublic = value
        },

        setSpend: (state, value)=>
        {
            state.spend = value
        },

        setSpendEveryMonth: (state, value)=>
        {
            state.spendEveryMonth = value
        },

        setSliderMutation: (state, value)=>
        {
            state.sliderThisTime = value
        },

        setServiceListMutation: (state, value)=>{

            state.serviceList = value
        }

    },
};

