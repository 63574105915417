import AuthenticationService from "../../services/authentication.service";
import alert from "../../helpers/alert";
import { permissions } from "../../configs/permission";
import {forEach,get,set,fromPairs,isEmpty} from 'lodash'
import response from "../../configs/message_response";
import router from "../../router";
const initialState = {
    jwt: localStorage.getItem('jwt') ? JSON.parse(localStorage.getItem('jwt')): null,
    profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null,
    permissions: localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null,
    isFristLogin: false,
};

export default {
    namespaced: true,
    state: initialState,
    actions: {
        async loginEmailPassword({ commit }, value) {
            var result = null
            try {
                result = await AuthenticationService.login(
                    value.cid,
                    value.password,
                    value.isSaveLogin
                );
            } 
            catch (error) 
            {
                alert.getAlert(get(response,`login_user_pwd.SERVER.message`),'error', 5000)
                return false
            }

            if(isEmpty(result))
            {
                alert.getAlert(get(response,`login_user_pwd.SERVER.message`),'error', 5000)
                return false
            }

            if(get(result,'status') == 'error')
            {
                if(get(response,`login_user_pwd.${result.message}.message`))
                {
                    alert.getAlert(get(response,`login_user_pwd.${result.message}.message`),'error', 5000)
                    return false
                }
                alert.getAlert(get(response,`login_user_pwd.SERVER.message`),'error', 5000)
                return false
            }
            commit("setJwt", get(result, 'data'));
            if(get(result,'data.is_first_time_login')){
                commit('setIsFristLogin', true)
            }
            return true;
        },

        async forgotCID(context, value) {
            try 
            {
                const result = await AuthenticationService.forgotCID(value)
                if (result.status === 'error') 
                {
                    switch (result.message) {
                        case 'INVALID_INPUT':
                            alert.getAlert('Email không hợp lệ', "error")
                            break;
                        case 'NOT_FOUND':
                            alert.getAlert('Không tìm thấy email trong hệ thống', "error")
                            break;
                        case 'FORBIDDEN':
                            alert.getAlert('Email của bạn bị cấm', "error")
                            break;
                        default:
                            alert.getAlert("Lỗi trong quá trình xử lý", "error")
                            break;
                    }
                    return false
                }
                alert.getAlert('Thông tin đã chuyển về email của bạn', "success")
                return true    
            } 
            catch (error) 
            {
                alert.getAlert("Server đang bảo trì.", "error")
                return false
            }
        },

        

        async resetPassword(context, payload) 
        {
            let result = null
            
            try 
            {
                result = await AuthenticationService.resetPassword(payload.value)
            } 
            catch ( error ) 
            {
                alert.getAlert("Server đang bảo trì.", "error")
                return false
            }

            if( !get(result,"code") )
            {
                alert.getAlert("Server đang bảo trì.", "error")
                return false
            }

            
            if ( 200 == result.code )
            {
    
                localStorage.removeItem("reset_token")
                alert.getAlert("Cập nhật mật khẩu thành công, mời bạn về trang đăng nhập.", "success", 2500, false)
                return true
            }

            if ( 403 == result.code )
            {
                localStorage.removeItem("reset_token")
                alert.getAlert("Token không hợp lệ.", "error", 2500)
                return false
            }
            
            if ( 400 == result.code )
            {
                localStorage.removeItem("reset_token")
                alert.getAlert("Dữ liệu nhập vào không hợp lệ.", "error", 2500)
                return false
            }

            alert.getAlert("Cập nhật mật khẩu không thành công.", "error", 2500)
            return false

        },

        async loginFireBase({ commit }) 
        {
            const result = await AuthenticationService.loginWithFireBase()
            
            if(isEmpty(result))
            {
                alert.getAlert(get(response,'firebase.SERVER.message'), "error");
                return false
            }

            if(!get(result,'status'))
            {
                alert.getAlert(get(response,'firebase.SERVER.message'), "error");
                return false
            }
            
            if (get(result,'status') == 'error') 
            {
                if(get(response,`firebase.${result.message}.message`))
                {
                    alert.getAlert(get(response,`firebase.${result.message}.message`), "error");
                    return false
                }
                alert.getAlert(get(response,'firebase.SERVER.message'), "error");
                return false
            }

            alert.getAlert(get(response,'firebase.SUCCESS.message'),'success',1500,false);
            commit('setJwt',{ access_token: get(result,'data.access_token') })
            return true
          },

        async getPermission( { commit } )
        {
            let result = null
            try 
            {
                result = await AuthenticationService.getPermission()
            } 
            catch (error) 
            {
                // alert.getAlert( 'Server đang bảo trì','error' )
                localStorage.clear()
                router.push('/login')
                return 
            }
            
            if(get(result,'status') == 'error')
            {
                if(get(response,`permission.${result.message}.auth`))
                {
                    // alert.getAlert(get(response,`permission.${result.message}.message`),'error')
                    localStorage.clear()
                    router.push('/login')
                    return
                }
                // alert.getAlert('Đã có lỗi xảy rả trong quá trính lấy phân quyền','error')
                localStorage.clear()
                router.push('/login')
                return
            }
            
            if(isEmpty(get(result,'data')))
            {
                // alert.getAlert( 'Đã có lỗi xảy rả trong quá trính lấy phân quyền','error' )
                localStorage.clear()
                router.push('/login')
                return 
            }

            const permisstionConvert = fromPairs( get( result, 'data' ).map( item => [
                item.module_name,
                fromPairs( item.permissions.map( perm => [
                    perm.name,
                    fromPairs( perm.scopes.map( scope => [
                        scope,
                        true
                    ]))
                ]))
            ]))        
                
            const permissionConfig = {}
            forEach(permisstionConvert,( value, key ) =>
            {
                const is_permission = Boolean( get( permissions, key ),false )
                if(is_permission)
                {
                    set(permissionConfig,`${key}`,value)
                }
            })
            
            commit( 'setPermission', permissionConfig )
        }
    },
    mutations: {
        setJwt(state, value) {
            state.jwt = value;
            localStorage.setItem("jwt", JSON.stringify(value));
        },
        setProfile(state, value) {
            state.profile = value;
            localStorage.setItem("profile", JSON.stringify(value));
        },
        setPermission(state, value) {
            state.permissions = value;
            localStorage.setItem("permissions", JSON.stringify(value));
        },
        setIsFristLogin(state, value) {
            state.isFristLogin = value;
        }
    },
};

