import _get from "lodash/get";
import store from "../../state";

export default [
    {
        path: "/admin/reports/contract/wip_in_month",
        name: "AdminReportContractWipInMonth",
        meta: {
            title: "Báo cáo spend khách hàng",
            header: "Báo cáo spend khách hàng",
        },
        component: () => import("../../views/admin/reports/ContractWipInMonth.vue"),
        beforeEnter(routeTo, routeFrom, next) {
            let permissions = store.state.authentication.permissions;
            let hasMangePermisison = _get(permissions, 'contract.report.manage', false);
            let hasReadPermisison = _get(permissions, 'contract.report.read', false);
            let hasPermisison = hasMangePermisison && hasReadPermisison;

            if(!hasPermisison)
            {
                next({ name: 'home' })
                return
            }

            next();
        },
    },
];
