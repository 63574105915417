<script setup>
import { 
    computed, 
    onBeforeMount
} from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import loadScript from "./helpers/loadScript"

var router = useRoute();
var store = useStore();

const getLayout = computed(()=>{
    if(router.meta.layout)
    {
        return router.meta.layout
    }
    return false
})

onBeforeMount(()=>
{
    loadScript.load_google_sign_in(store);
})
</script>

<template>
    <component :is="getLayout + '-layout'">
        <router-view></router-view>
    </component>
</template>