
// import alert from "../../helpers/alert";
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
const initialState = {
    sync_keys: [],
    sync_data_request_id: JSON.parse(localStorage.getItem('dashboard_sync_data_request_id')) || '',
    is_show_modal_sync_data: false,
    is_loading_sync_progress: JSON.parse(localStorage.getItem('dashboard_is_loading_sync_progress')) || false,
    is_get_ad_account: false,
    is_get_ad_campaign: false,
    is_get_ad_group: false,
    is_get_ad_region: false,
    is_get_ad_device: false,
    is_get_ad_gender: false,
};

export default {
    namespaced: true,
    state: initialState,
    actions:
    {
        setSyncData: async({commit}, sync_keys = []) =>
        {
            commit('setSyncDataSate',sync_keys)
        },
        
        setGetDataVariable: ({commit},value) => {
            commit('setGetDataVariable',value)
        },

        setShowModalSyncData: ({commit},value) => {
            commit('setShowModalSyncDataSate',value)
        },
        
        setIsLoadingSyncProgress: ({commit},value) => {
            commit('setIsLoadingSyncProgressState',value)
        },

        setSyncDateRequestId: ({commit}, value) => {
            commit('setSyncDateRequestIdState', value)
        }
    },
    
    mutations: 
    {
        setSyncDataSate: (state,value) =>
        {
            state.sync_keys = value
        },

        setGetDataVariableState: (state,view_type) => {
            state[view_type.key] = view_type.value
        },
        setShowModalSyncDataSate: (state, value) => {
            state.is_show_modal_sync_data = value
        },

        setIsLoadingSyncProgressState: (state, value) => {
            state.is_loading_sync_progress = value
            localStorage.setItem('dashboard_is_loading_sync_progress', JSON.stringify(value))
        },

        setSyncDateRequestIdState: (state, value) => {
            state.sync_data_request_id = value
            localStorage.setItem('dashboard_sync_data_request_id', JSON.stringify(value))
        }
    }
}
