import AdminRoutes from "./admin";

let routes =  [
    {
        path: "/",
        name: "loading",
        meta: {
            title: "Loading",
        },
        component: () => import("../views/loading/index.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            layout: "auth",
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
            title: "Forgot Password",
            layout: "auth",
        },
    },
    {
        path: "/forgot-cid",
        name: "forgot-cid",
        component: () => import("../views/account/forgot-cid.vue"),
        meta: {
            title: "Forgot CID",
            layout: "auth",
        },
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("../views/account/reset-password.vue"),
        meta: {
            title: "Reset Password",
            layout: "auth",
        },
    },
    {
        path: "/home",
        name: "home",
        meta: {
            title: "Home",
            layout: "main",
        },
        component: () => import("../views/home/index"),
    },
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            layout: "main",
        },
        component: () => import("../views/dashboard/overview/index"),
    },
    {
        path: "/app/contracts",
        name: "app-contracts",
        meta: {
            title: "Contract list",
            layout: "main",
            permission: 'contract'
        },
        component: () => import("../views/app/contractList.vue"),
    },
    {

        path: "/app/contract/:id/report",
        name: "contract-detail-report-overview",
        meta: {
            title: "contract_code || Contract report",
            layout: "main",
            permission: 'contract'
        },
        component: () => import("../views/apps/contract/contractDetailReport"),
    },
    {
        path: "/app/contract/:id/information",
        name: "contract-detail-more-info",
        meta: {
            title: "contract_code || Infomation Contract",
            layout: "main",
            permission: 'contract'
        },
        component: () => import("../views/apps/contract/contractDetailInf"),
    },
    {
        path: "/profile",
        name: "profile",
        meta: {
            title: "Profile",
            layout: "main",
        },
        component: () => import("../views/profile/index.vue"),
    },
    {
        path: "/profile/change/password",
        name: "change-password",
        meta: {
            title: "Change password",
            layout: "auth",
        },
        component: () => import("../views/profile/change-password.vue"),
    },
    {
        path: "/app/receipts",
        name: "receipts",
        meta: {
            title: "Receipt",
            layout: "main",
            permission: 'contract'
        },
        component: () => import("../views/apps/invoices/index.vue"),

    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout",
            layout: "auth",
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import("../views/auth/logout/basic"),
    },
    {
        path: "/admin",
        name: "Administrator",
        meta: {
            title: "Administrator",
            layout: "main",
        },
        component: () => import("../views/admin/AdminLayout.vue"),
        children: [ ...AdminRoutes ]
    },
];

export default routes;