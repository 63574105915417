import app from "./modules/app.module";
import authentication from "./modules/auth.module";
import layout from "./modules/layout.module";
import contract from "./modules/contract.module";

import dashboard from "./modules/dashboard.module";

import profile from "./modules/profile.module";
import home from "./modules/home.module";
import sync_data from "./modules/sync_data.module";

import { createStore } from "vuex";

const store = createStore({
    modules: {
        app,
        authentication,
        layout,
        contract,
        dashboard,
        profile,
        home,
        sync_data

    },
    strict: process.env.NODE_ENV !== "production",
});

export default store;