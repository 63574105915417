<script setup>
    import { computed, onMounted, ref, watch } from "vue";
    import { useStore } from "vuex";
    import DashboardService from "@/services/dashboardService.service";
    import response from "@/configs/message_response";
    import { getToastError } from "@/helpers/toast";
    import _get from 'lodash/get'
    import _isEmpty from 'lodash/isEmpty'
import { get_filter_time_by_dimension, get_filter_time_by_dimension_contract } from "@/helpers/dimension";
    const store = useStore()
    const show_modal = computed(() =>store.state.sync_data.is_show_modal_sync_data )
    const is_loading_sync_progress = computed(() =>store.state.sync_data.is_loading_sync_progress )
    const sync_data_request_id = computed(() =>store.state.sync_data.sync_data_request_id )
    const dimensionDashboard = computed(() => store.state.dashboard.chooseTime)
    const dimensionContract = computed(() => store.state.contract.chooseTime)

    const is_loading_sync = ref(false)
    const is_view_detail_modal = ref(false)

const flavours = ref([
    { text: 'Chi phí, lượt nhấp chuột, lượt hiển thị, tỷ lệ nhấp chuột', value: 'ad_account', },
    { text: 'Báo cáo theo chiến dịch', value: 'ad_campaign', },
    { text: 'Báo cáo theo nhóm quảng cáo', value: 'ad_group', },
    { text: 'Báo cáo theo mẫu quảng cáo', value: 'ad', },
    { text: 'Báo báo theo giới tính', value: 'ad_gender', },
    { text: 'Báo cáo theo độ tuổi', value: 'ad_age_range', },
    { text: 'Báo cáo theo lượt nhấp chuột theo vùng', value: 'ad_region', },
    { text: 'Báo cáo theo Thiết bị', value: 'ad_device', },
    { text: 'Báo cáo theo từ khoá quảng cáo (dành cho dịch vụ Google ads nếu có)', value: 'ad_keyword',},
]);
const selected = ref([
    'ad_account',
    'ad_campaign',
    'ad_group',
    'ad',
    'ad_gender',
    'ad_age_range',
    'ad_region',
    'ad_device',
    'ad_keyword',
]);
const allSelected = ref(true);
const indeterminate = ref(false);
const sync_progress_request = ref({})
const interval_progress_id = ref(null)
const label_sync_data = ref({
    'ad_account': 'Chi phí, lượt nhấp chuột, lượt hiển thị, tỷ lệ nhấp chuột', 
    'ad_campaign': 'Báo cáo theo chiến dịch', 
    'ad_group': 'Báo cáo theo nhóm quảng cáo', 
    'ad': 'Báo cáo theo mẫu quảng cáo', 
    'ad_gender': 'Báo báo theo giới tính', 
    'ad_age_range': 'Báo cáo theo độ tuổi', 
    'ad_region': 'Báo cáo theo lượt nhấp chuột theo vùng', 
    'ad_device': 'Báo cáo theo Thiết bị',
    'ad_keyword': 'Báo cáo theo từ khoá quảng cáo (dành cho dịch vụ Google ads nếu có)',
})
const toggleAll = (checked) => {
    selected.value = checked ? [...flavours.value.map((f) => f.value)] : [];
};

const handleSetSycnData = async (sync_keys) => {
    is_loading_sync.value = true
    var result = {}
    try {
        let payload = {
            view_types: sync_keys,
        }
        const contract_id = window.location.pathname.split("/")[3]

        
        if (!_isEmpty(contract_id)) {
            payload = {
                ...payload,
                contract_id: contract_id
            }
        }
        
        const dimension = _isEmpty(contract_id) ? getDimension( 'dashboard' ) : getDimension( 'contract' )

        payload = {
            ...payload,
            ...dimension,
        }
        
        const res = await DashboardService.syncDateReportPerformance(payload)
        result = res
    } catch (error) {
        getToastError('Server đang bảo trì, vui lòng thử lại sau!')
        return
    } finally {
        is_loading_sync.value = false
    }

    if (_isEmpty(result)) {
        getToastError('Server đang bảo trì, vui lòng thử lại sau!')
        return
    }

    if ("error" == _get(result, "status")) {
        if (true == _get(response, 'public.' + _get(result, "message") + '.auth')) {
            const toast_time = 2000
            getToastError(_get(response, 'public.' + _get(result, "message") + '.message'), toast_time)
            setTimeout(() => {
                localStorage.clear()
                window.location.reload()
            }, toast_time);
            return
        }
        getToastError(_get(response, 'public.' + _get(result, "message") + '.message'))
        return
    }

    store.dispatch('sync_data/setShowModalSyncData', false)
    // getToastSuccess('Chúng tôi đã nhận được yêu cầu và đang tiến hành đồng bộ dữ liệu, quá trình này sẽ mất khoảng 5 đến 10 phút. Xin hãy đợi một ít phút nhé!',15000)
    followSyncDataProgress(_get(result, "data.request_id"))
}

const handleCheckRequest = async (request_id) => {
    var result = {}

    try {
        result = await DashboardService.syncDateReportPerformanceCheckRequest({
            request_id
        })
    } catch (error) {
        getToastError('Server đang bảo trì, vui lòng thử lại sau!')
        store.dispatch('sync_data/setIsLoadingSyncProgress', false)
        clearInterval(interval_progress_id.value)
        return
    }

    if (_isEmpty(result)) {
        getToastError('Server đang bảo trì, vui không thử lại sau!')
         store.dispatch('sync_data/setIsLoadingSyncProgress', false)
        clearInterval(interval_progress_id.value)
        return
    }

    if ("error" == _get(result, "status")) {
        if (true == _get(response, 'public.' + _get(result, "message") + '.auth')) {

            const toast_time = 2000

            getToastError('public.' + _get(result, "message"), toast_time)

            setTimeout(() => {
                localStorage.clear()
                window.location.reload()
            }, toast_time);
             store.dispatch('sync_data/setIsLoadingSyncProgress', false)
            clearInterval(interval_progress_id.value)
            return
        }
        getToastError('public.' + _get(result, "message"))
         store.dispatch('sync_data/setIsLoadingSyncProgress', false)
        clearInterval(interval_progress_id.value)
        return
    }

    sync_progress_request.value = _get(result, 'data')
    // setVairableReGetData(_get(result, 'data'))
}

// const setVairableReGetData = request_data => {
//     const view_types = _get(request_data, 'view_types')
//     if(_get(request_data, 'is_finish')) {

//         for (let index = 0; index < view_types.length; index++) {
//             const element = view_types[index];
//             store.dispatch('dashboard/setGetDataVariable', {
//                 view_type: element.view_type_name,
//                 value: false,
//             })
//         }
//         return
//     }
    
//     for (let index = 0; index < view_types.length; index++) {
//         const element = view_types[index];
//         if("success" == element.status){
//             store.dispatch('dashboard/setGetDataVariable', {
//                 view_type: element.view_type_name,
//                 value: true,
//             })
//         }
//     }
    
// }


const followSyncDataProgress = async (request_id) => {
    if (_isEmpty(String(request_id))) {
        getToastError('Không theo dõi được tiến trình!')
        return
    }
    store.dispatch('sync_data/setSyncDateRequestId', request_id)
    store.dispatch('sync_data/setIsLoadingSyncProgress', true)
    interval_progress_id.value = setInterval(() => {
        handleCheckRequest(request_id)
    }, 5000)
}


const handleReloadPage = () => {
    window.location.reload()
}

onMounted(() => {
    if(_isEmpty(String(sync_data_request_id.value)) || !is_loading_sync_progress.value){
        return
    }
    followSyncDataProgress(sync_data_request_id.value)
})


watch(selected, (newValue) => {
    if (newValue.length === 0) {
        indeterminate.value = false;
        allSelected.value = false;
    } else if (newValue.length === flavours.value.length) {
        indeterminate.value = false;
        allSelected.value = true;
    } else {
        indeterminate.value = true;
        allSelected.value = false;
    }

});

watch(sync_progress_request, (newValue) => {
    if (_get(newValue, 'is_finish')) {
         store.dispatch('sync_data/setIsLoadingSyncProgress', false)
         store.dispatch('sync_data/setSyncDateRequestId', '')
        clearInterval(interval_progress_id.value)
    }
})


watch(is_loading_sync_progress, (newValue) => {
    store.dispatch("sync_data/setIsLoadingSyncProgress", newValue)
})


const stopSyncProgress = () => {
    store.dispatch('sync_data/setIsLoadingSyncProgress', false)
    store.dispatch('sync_data/setSyncDateRequestId', '')
    clearInterval(interval_progress_id.value)
}


const pasertDimension = dimension => {
    if( dimension == 'day' ){
        return 'week'
    }
    return dimension
}

const getDimension = (type) => {
    const dimension = {
        'dashboard': {
            from: get_filter_time_by_dimension(pasertDimension(dimensionDashboard.value)).from,
            to: get_filter_time_by_dimension(pasertDimension(dimensionDashboard.value)).to,
        },
        'contract': {
            from: get_filter_time_by_dimension_contract(pasertDimension(dimensionContract.value)).from,
            to: get_filter_time_by_dimension_contract(pasertDimension(dimensionContract.value)).to,
        }
    }
    return dimension[type]
}
</script>

<template>
    <div class="modal_container " data-aos="fade" v-if="show_modal">
        <BOverlay id="overlay-background" :show="is_loading_sync" :variant="'light'" :opacity="0.85" :blur="'2px'"
            rounded="sm">
            <b-card no-body data-aos="fade" class="position-relative m-0" style="width: 840px;">
                <b-card-header>
                    <b-card-title class="d-flex align-items-center justify-content-between m-0">
                        <h5 class="m-0 text-uppercase">
                            {{ $t('t-data-synchronization') }}
                        </h5>
                        <b-button variant="soft-danger" size="sm" @click="store.dispatch('sync_data/setShowModalSyncData', false)">
                            <i class='bx bx-x text-h4 mt-1'></i>
                        </b-button>
                    </b-card-title>
                </b-card-header>
                <b-card-body>
                    <div :style="{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
                    }">
                        <b-form-group>
                            <template #label>
                                <b class="text-nowrap">Chọn chỉ số quảng cáo bạn muốn lấy mới nhất:</b><br>
                                <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate"
                                    aria-describedby="flavours" aria-controls="flavours" switch @change="toggleAll">
                                    {{ allSelected ? 'Bỏ chọn tất cả' : 'Chọn tất cả' }}
                                </b-form-checkbox>
                            </template>

                            <template v-slot="{ ariaDescribedby }">
                                <b-form-checkbox-group id="flavors" v-model="selected" :options="flavours"
                                    :aria-describedby="ariaDescribedby" name="flavors" class="ml-4 gap-2 mt-3"
                                    aria-label="Individual flavours" stacked switches></b-form-checkbox-group>
                            </template>
                        </b-form-group>
                        <div class="d-flex align-items-center rounded-pill justify-content-center">
                            <img src="../../assets/images/order/sync_data.png" alt="" class="w-100 h-100 object-contain">
                        </div>
                    </div>
                </b-card-body>
                <b-card-footer class="d-flex align-items-center gap-2 justify-content-end">
                    <b-button variant="danger" @click="store.dispatch('sync_data/setShowModalSyncData', false)">
                        {{ is_loading_sync_progress ? 'Đóng' : 'Huỷ' }}
                    </b-button>
                    <b-button @click="handleSetSycnData(selected)"
                        :disabled="selected.length == 0 || is_loading_sync || is_loading_sync_progress">
                        {{ is_loading_sync_progress ? 'Đang đồng bộ dữ liệu...' : 'Áp dụng' }}
                    </b-button>
                </b-card-footer>
            </b-card>
        </BOverlay>
    </div>
    <div class="modal_sync_data_mini" v-if="is_loading_sync_progress"
    >
        <b-card no-body class="m-0 shadow-lg"
        >
            <b-card-header>
                <b-card-title class="d-flex align-items-center justify-content-between">
                    <b>Tiến trình đồng bộ dữ liệu</b>
                    <BSpinner 
                        small class="flex-shrink-0"
                        v-if="is_loading_sync_progress"
                    />
                </b-card-title>
                <b-card-body class="mt-1 p-0">
                    <Transition>
                        <div class="d-flex flex-column gap-2 mb-2" v-if="is_view_detail_modal">
                            <div class="d-flex align-items-center justify-content-between"
                                v-for="(item, index) in _get(sync_progress_request, 'view_types')" :key="index"
                            >
                                <div>
                                   {{ _get(label_sync_data, _get(item, 'view_type_name')) }}
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-center align-items-center"
                                        :style="{
                                            width: '28px',
                                            height: '28px',
                                        }"
                                    >
                                        <BSpinner small class="flex-shrink-0"
                                            v-if="Number(_get(item, 'percent_complete')) < 100"
                                        />
                                        <i class='bx bx-check text-success fs-2' data-aos="zoom-in"
                                            v-if="'success' == _get(item, 'status') && Number(_get(item, 'percent_complete')) == 100"
                                        ></i>
                                        <i class='bx bx-x text-danger fs-2' data-aos="zoom-in"
                                            v-if="('error' == _get(item, 'status') || 'unspecified' == _get(item, 'status')) && Number(_get(item, 'percent_complete')) == 100"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <div class="d-flex align-items-center gap-2">
                    </div>
                    <div class="text-end mt-1 d-flex align-items-center justify-content-between">
                        <b-button size="sm" variant="danger" @click="stopSyncProgress">
                            Dừng đồng bộ
                        </b-button>
                        <span class="cursor-pointer d-block"
                            :class="{
                                'link-danger': is_view_detail_modal,
                                'link-primary': !is_view_detail_modal
                            }"
                           @click="is_view_detail_modal = !is_view_detail_modal"
                        > 
                            {{ is_view_detail_modal ? 'Ẩn bớt' : 'Xem chi tiết' }}
                        </span>
                    </div>
                </b-card-body>
            </b-card-header>
        </b-card>
    </div>
    <div class="modal_sync_data_mini" data-aos="fade-right"
        v-if="_get(sync_progress_request, 'is_finish')" 
    >
        <b-card no-body class="m-0 shadow-lg"
        >
            <b-card-header>
                <b-card-title>
                    <b>Hoàn thành đồng bộ dữ liệu</b>
                </b-card-title>
            </b-card-header>
            <b-card-body>
                <div class="d-flex flex-column gap-2 mb-2">
                    <div class="d-flex align-items-center justify-content-between"
                        v-for="(item, index) in _get(sync_progress_request, 'view_types')" :key="index"
                    >
                        <div>
                           {{ _get(label_sync_data, _get(item, 'view_type_name')) }}
                        </div>
                        <div class="d-flex justify-content-center align-items-center"
                            :style="{
                                width: '28px',
                                height: '28px',
                            }"
                        >
                            <BSpinner small class="flex-shrink-0"
                                v-if="'pending' == _get(item, 'status') || 'in_progress' == _get(item, 'status')"
                            />
                            <i class='bx bx-check text-success fs-2' data-aos="zoom-in"
                                v-if="'success' == _get(item, 'status')"
                            ></i>
                            <i class='bx bx-x text-danger fs-2' data-aos="zoom-in"
                                v-if="'error' == _get(item, 'status') || 'unspecified' == _get(item, 'status')"
                            ></i>
                        </div>
                    </div>
                </div>
                
            </b-card-body>
            <b-card-footer>
                <div class="d-flex flex-column gap-2 text-sub text-muted">
                    <div>
                        Dữ liệu của bạn đã được đồng bộ hoàn tất. Tải lại trang để xem dữ liệu mới nhất!
                    </div>
                    <div class="d-flex align-items-center gap-2 justify-content-end">
                        <b-button size="sm" class="text-nowrap"
                            @click="handleReloadPage"
                        >
                            Tải lại trang
                        </b-button>
                        <b-button size="sm" class="text-nowrap" variant="danger"
                            @click="sync_progress_request = {}"
                        >
                            Để sau
                        </b-button>
                    </div>
                </div>
            </b-card-footer>
        </b-card>
    </div>
</template>

<style lang="scss" scoped>
.modal_container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal_sync_data_mini{
    position: fixed;
    left: 20px;
    bottom: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1050;
    width: 25%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>