
import {
    API_FORGOT_PWD,
    API_LOGIN_USER_PWD,
    API_LOGIN_FIREBASE,
    API_PERMISSION,
    API_CONTRACTS,
    API_FORGOT_CID,
    API_RESET_PWD,
    API_CONTRACT_DETAIL,
    API_REPORTS,


    API_PROFILE,
    API_CONTRACT_STATISTIC,
    API_PROFILE_UPDATE,
    API_FOLLOW_SERVICE,
    API_UN_FOLLOW_SERVICE,
    API_CONTRACT_RECEIPTS_STATISTIC,
    API_CONTRACT_RECEIPTS,
} from "../configs/api"



const FAKE_DATA = {

    [API_FORGOT_PWD]: (cid) => {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                if ("123456" != cid.user_name) {
                    resolve({
                        "status": "error",
                        "message": "Check email notifications",
                        "code": 404,
                        "data": []
                    })
                }

                resolve({
                    "status": "success",
                    "message": "Check email notifications",
                    "code": 200,
                    "data": {
                        "email": "nam*********s@*****.com"
                    }
                })
            }, 2000);
        });

        return myPromise
    },



    [API_PERMISSION]: () => {
        const promiss = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: [
                            {
                                module_name: 'contract',
                                permissions: [
                                    {
                                        name: 'googleads',
                                        scopes: [
                                            'create',
                                            'read',
                                        ]
                                    },
                                    {
                                        name: 'facebookads',
                                        scopes: [
                                            'create',
                                            'read',
                                        ]
                                    },
                                ]
                            },
                        ]
                    }
                )
            }, 2500);
        })
        return promiss
    },


    [API_RESET_PWD]: (value) => {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                if ("123456" != value.reset_token) {
                    resolve({
                        code: 403,
                        message: "Token không hợp lệ.",
                        data: [],
                        status: "error",
                    })
                }

                resolve({
                    code: 200,
                    message: "Cập nhật mật khẩu thành công.",
                    data: [],
                    success: "success",
                })
            }, 2000);
        });

        return myPromise
    },

    [API_CONTRACT_STATISTIC]: () => {

        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data":
                    {
                        "contract":
                        {
                            "total": 20,
                            "num_of_publish": 16,
                            "num_of_other": 1,
                        },
                        "total_publish_contract_value": 2234042000,
                        "total_receipt_paid": 10000000,
                        "total_remain_receipt": 10000000
                    }
                })
            }, 4000);
        });

        return myPromise
    },

    [API_LOGIN_USER_PWD]: () => {
        const promiss = new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "contract": {
                            "total": 3,
                            "num_of_publish": 2,
                            "num_of_other": 1,
                        },
                        "total_publish_contract_value": 20000000,
                        "total_receipt_paid": 10000000,
                        "total_remain_receipt": 10000000
                    }
                })
            }, 1000);
        });

        return promiss
    },

    [API_LOGIN_FIREBASE]: (body) => {
        if (body.user.accessToken) {
            return (
                {
                    status: 'success',
                    message: 'Đăng nhập thành công',
                    code: 200,
                    data: {
                        access_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50X2lkIjoxLCJjb250YWN0X2lkIjoyLCJleHAiOjE2ODU0MDQ3OTl9.hLdUp_JgzpgBegrw30Qm_BPtV-5ek9l7ebMWE6dRwKs'
                    }
                }
            )
        }
        return (
            {
                status: 'error',
                message: 'Email của bạn không có trong hệ thống',
                code: 404,
                data: {
                    errors: "account.is_null"
                }
            }
        )
    },


    [API_CONTRACTS]: () => {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            data: [
                                {
                                    id: '1',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'google-ads',
                                    status: 'publish',
                                    percent_progress: 0.40,
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business:
                                    {
                                        id: '1',
                                        display_name: 'Ads Plus',
                                        email: 'teach@adsplus.vn',
                                        phone: '0999999999',
                                        avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                    },
                                    staff_advertisers: [],
                                    "adsvertise_start_time": 1682911817,
                                    "adsvertise_end_time": 1685417417,
                                    "service_started_at": 1685417417,
                                    "service_activated_at": 1685417417,
                                    "verified_at": 1685417417,
                                    "created_at": 1685417417,
                                    "updated_at": 1685417417,
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    status: 'ending',
                                    percent_progress: 0.70,
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business:
                                    {
                                        id: '1',
                                        display_name: 'Ads Plus',
                                        email: 'teach@adsplus.vn',
                                        phone: '0999999999',
                                        avatar_url: null,
                                    },
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: ''
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ],
                                    "adsvertise_start_time": 1682911817,
                                    "adsvertise_end_time": 1685417417,
                                    "service_started_at": 1685417417,
                                    "service_activated_at": 1685417417,
                                    "verified_at": 1685417417,
                                    "created_at": 1685417417,
                                    "updated_at": 1685417417,
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    percent_progress: 0.90,
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business: null,
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ]
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business: null,
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ]
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business: null,
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ]
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business: null,
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ]
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business: null,
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ]
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business: null,
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ]
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    contract_payment:
                                    {
                                        date: 1682911817,
                                        value: 1000000000,
                                    },
                                    staff_business: null,
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ],
                                    "adsvertise_start_time": 1682911817,
                                    "adsvertise_end_time": 1685417417,
                                    "service_started_at": 1685417417,
                                    "service_activated_at": 1685417417,
                                    "verified_at": 1685417417,
                                    "created_at": 1685417417,
                                    "updated_at": 1685417417,
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    percent_progress: 0.10,
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    staff_business:
                                    {
                                        id: '1',
                                        display_name: 'Ads Plus',
                                        email: 'teach@adsplus.vn',
                                        phone: '0999999999',
                                        avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                    },
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ],
                                    "adsvertise_start_time": 1682911817,
                                    "adsvertise_end_time": 1685417417,
                                    "service_started_at": 1685417417,
                                    "service_activated_at": 1685417417,
                                    "verified_at": 1685417417,
                                    "created_at": 1685417417,
                                    "updated_at": 1685417417,
                                },
                                {
                                    id: '2',
                                    code: '1234/5678/GOOGLEADS/EXAMPLE.COM',
                                    type: 'facebook-ads',
                                    percent_progress: 0.20,
                                    status: 'remove',
                                    website: 'https://www.figma.com/file/Cqjf1dj5n46jk84z7OP7iX/Mockup-Guru-Customer-Portal?type=design&node-id=1-2&t=qLzHQaQAFIksyIUF-0',
                                    contract_value: 100000000,
                                    contract_begin: 1682911817,
                                    contract_end: 1685417417,
                                    staff_business:
                                    {
                                        id: '1',
                                        display_name: 'Ads Plus',
                                        email: 'teach@adsplus.vn',
                                        phone: '0999999999',
                                        avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                    },
                                    staff_advertisers:
                                        [
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                            {
                                                id: '2',
                                                display_name: ' Google staff',
                                                email: ' googleads@adsplus.vn',
                                                phone: ' 0909999999',
                                                avatar_url: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1685680178~exp=1685680778~hmac=50616d95c0eaf2d3292ea35f1613f1835b7b3b0776fd6e5779cffc6c59629ae0'
                                            },
                                        ],
                                    "adsvertise_start_time": 1682911817,
                                    "adsvertise_end_time": 1685417417,
                                    "service_started_at": 1685417417,
                                    "service_activated_at": 1685417417,
                                    "verified_at": 1685417417,
                                    "created_at": 1685417417,
                                    "updated_at": 1685417417,
                                },
                            ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 3
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    [API_FORGOT_CID]: (body) => {
        const promiss = new Promise(resolve => {
            setTimeout(() => {
                if ('lebaonhi12c1@gmail.com' == body.email) {
                    resolve(
                        {
                            code: 200,
                            status: 'success',
                            message: 'SUCCESS',
                            data: []
                        }
                    )
                }

                resolve(
                    {
                        code: 404,
                        status: 'error',
                        message: 'NOT_FOUND',
                        data: []
                    }
                )

            }, 2000);
        });

        return promiss
    },

    [API_LOGIN_USER_PWD]: (body) => {
        const promiss = new Promise(resolve => {
            setTimeout(() => {
                if ("CO000001" !== body.user_name || "123456789" !== body.password) {
                    resolve({
                        code: 404,
                        status: 'error',
                        message: 'INVALID_INPUT',
                    })
                }
                resolve({
                    code: 200,
                    status: 'success',
                    message: 'SUCCESS',
                    data: {
                        refresh_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
                        access_token: ' eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNjg1NDE3NDE3fQ.HT0NujoJ5MQUBRfkNOmRnRzNsgXQPRbtBbChxRi-3Io',
                        access_token_ttl: 1684294217
                    }
                })
            }, 2000);
        })
        return promiss
    },



    [API_CONTRACT_DETAIL]: (url) => {
        const promiss = new Promise(resolve => {
            if ("1" == url.split("/")[2]) {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "Lấy dữ liệu thành công",
                        "data":
                        {
                            "id": 1,
                            "code": "1234/5678/GOOGLEADS/EXAMPLE.COM",
                            "type": "google-ads",
                            "status": "publish",
                            "website": "example.com",
                            "contract_value": 1159000,
                            "contract_begin": 1682911817,
                            "contract_end": 1685417417,
                            "staff_business":
                            {
                                "id": 1,
                                "display_name": "Ads Plus",
                                "email": "teach@adsplus.vn",
                                "phone": "0999999999",
                                "avatar_url": "https://example.com/image.png"
                            },
                            "staff_advertisers":
                                [
                                    {
                                        "id": 1,
                                        "display_name": "Tiktok staff",
                                        "email": "googleads@adsplus.vn",
                                        "phone": "0909999999",
                                        "avatar_url": "https://example.com/image.png"
                                    },
                                    {
                                        "id": 3,
                                        "display_name": "Facebook staff",
                                        "email": "googleads@adsplus.vn",
                                        "phone": "0909999999",
                                        "avatar_url": "https://example.com/image.png"
                                    },
                                    {
                                        "id": 2,
                                        "display_name": "Google staff",
                                        "email": "googleads@adsplus.vn",
                                        "phone": "0909999999",
                                        "avatar_url": "https://example.com/image.png"
                                    }
                                ],
                            "representative":
                            {
                                "name": "Ads Plus customer",
                                "email": "customer@adsplus.vn",
                                "phone": "0999000999",
                            },
                            "adsvertise_start_time": 1682911817,
                            "adsvertise_end_time": 1685417417,
                            "service_started_at": 1685417417,
                            "service_activated_at": 1685417417,
                            "verified_at": 1685417417,
                            "created_at": 1685417417,
                            "updated_at": 1685417417,
                        }
                    }
                )
            }

            resolve(
                {
                    "code": "404",
                    "status": "error",
                    "message": "NOT_FOUND",
                    "data": []
                }
            )
        }, 2000);

        return promiss
    },


    [API_CONTRACT_RECEIPTS]: () => {
        const promiss = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: "200",
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            data:
                                [
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "google-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "cash",
                                        "status": "unpaid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "facebook-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "paid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "cancel",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "paid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "refund",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "paid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "paid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "paid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "paid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                    {
                                        "id": 1,
                                        "code": "72592/0523/1WEB/FACEBOOK.COM/SO1XALA",
                                        "contract_type": "tiktok-ads",
                                        "type": "receipt_payment",
                                        "payment_type": "account",
                                        "status": "paid",
                                        "payment_date": 1685341245,
                                        "amount": 18150000,
                                        "vat": 1650000,
                                        "vat_rate": 10,
                                        "fct": 0,
                                        "fct_rate": 0,
                                        "google_service_fee": 0,
                                        "google_service_fee_rate": 0,
                                        "service_fee": 16500000,
                                        "service_fee_rate": 20,
                                        "budget": 82500000,
                                        "staff_business": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "staff_accountant": {
                                            "display_name": "Tan Huynh Nhat",
                                            "email": "tanhn@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.jpg"
                                        },
                                        "created_at": 1685341245,
                                        "updated_at": 1685341245
                                    },
                                ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 2
                        }
                    }
                )

            }, 2000);
        })
        return promiss
    },




    [API_PROFILE]: () => {
        const promiss = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data": {
                            "id": 1,
                            "type": "customer_company",
                            "cid": "CO000001",
                            "bio": "Hi I'm Anna Adame,It will be as simple as Occidental; in fact, it will be Occidental. To an English person,it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is European languages are members of the same family.",
                            "display_name": "Tan Huynh Nhat",
                            "avatar": "https://img.freepik.com/free-photo/cheerful-beautiful-young-asian-woman-feeling-happy-smiling-camera-while-traveling-chinatown-beijing-china_7861-1341.jpg?w=1060&t=st=1686210110~exp=1686210710~hmac=b74b0893819830259c200e159cad56a065ad1895f2eebb4abedfacab1ff6dfcf",
                            "phone": "0999999999",
                            "email": "tanhn@adsplus.vn",
                            "address": "402 Nguyen Thi Minh Khai, ...",
                            "gender": "male",
                            "company": "AdsPlus",
                            "tax_code": "1234567890",
                            "fax": "123456789",
                            "job_title": "",
                            "websites": [
                                "adsplus.vn",
                                "adsplus.vn",
                                "adsplus.vn",
                                "adsplus.vn",
                                "adsplus.vn",

                            ],
                            "social_links": [
                                {
                                    "type": "facebook",
                                    "link": "https://facebook.com/xxxxxxxxxx"
                                },
                                {
                                    "type": "tiktok",
                                    "link": ""
                                },
                            ],
                            "industries": [
                                "Quảng cáo",
                                "Công nghệ"
                            ],
                            "service_followed": [
                                "google-ads",
                                "facebook-ads"
                            ],
                            "created_at": 1685341245,
                            "updated_at": 1685341245
                        }
                    }
                )
            }, 20000)
        })
        return promiss
    },


    [API_CONTRACT_RECEIPTS_STATISTIC]: () => {
        const promiss = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data": {
                            "total_receipt_paid": 0,
                            "num_of_total_receipt_paid": 10,

                            "latest_receipt_paid": 111000000,
                            "num_of_latest_receipt_paid": 10,

                            "receipt_due_payment": 100000,
                            "num_of_receipt_due_payment": 10,

                            "dimension": {

                                "total_receipt_paid": 1000,
                                "num_of_total_receipt_paid": 10,

                                "latest_receipt_paid": 1000000,
                                "num_of_latest_receipt_paid": 10,

                                "receipt_due_payment": 1000,
                                "num_of_receipt_due_payment": 10,
                            }
                        }
                    }
                )
            }, 2000);
        })
        return promiss
    },

    [API_REPORTS]: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data":
                    {
                        'data': [
                            {
                                "cost": 12000000,
                                "spend": 1232134,

                            },
                        ]
                    }
                })


            }, 3000);
        });

        return myPromise
    },



    [API_PROFILE_UPDATE]: (body) => {
        const promiss = new Promise((resolve) => {
            setTimeout(() => {
                if (body) {
                    resolve(
                        {
                            "code": "200",
                            "status": "success",
                            "message": "SUCCESS",
                            "data": {
                                "id": 1,
                                "updated_at": 1685341245
                            }
                        }
                    )
                }

                resolve(
                    {
                        "code": "200",
                        "status": "error",
                        "message": "NOt_FOUND ",
                        "data": {
                            "id": 1,
                            "updated_at": 1685341245
                        }
                    }
                )
            }, 2000);
        })
        return promiss
    },

    [API_FOLLOW_SERVICE]: (body) => {
        const promiss = new Promise((resolve) => {
            setTimeout(() => {
                if (body) {
                    resolve(
                        {
                            "code": "200",
                            "status": "success",
                            "message": "SUCCESS",
                            "data": {
                                "id": 1,
                                "service_followed":
                                    [
                                        "google-ads",
                                        "facebook-ads"
                                    ]
                            }
                        }
                    )
                }

                resolve(
                    {
                        "code": "404",
                        "status": "error",
                        "message": "NOt_FOUND ",
                        "data": {
                            "id": 1,
                            "updated_at": 1685341245
                        }
                    }
                )
            }, 2000);
        })
        return promiss
    },

    [API_UN_FOLLOW_SERVICE]: (body) => {
        const promiss = new Promise((resolve) => {
            setTimeout(() => {
                if (body) {
                    resolve(
                        {
                            "code": "200",
                            "status": "success",
                            "message": "SUCCESS",
                            "data": {
                                "id": 1,
                                "service_followed":
                                    [
                                        "facebook-ads"
                                    ]
                            }
                        }
                    )
                }

                resolve(
                    {
                        "code": "404",
                        "status": "error",
                        "message": "NOt_FOUND ",
                        "data": {
                            "id": 1,
                            "updated_at": 1685341245
                        }
                    }
                )
            }, 2000);
        })
        return promiss
    },


    [API_LOGIN_FIREBASE]: (body) => {
        if (body.user.accessToken) {
            return (
                {
                    status: 'success',
                    message: 'SUCCESS',
                    code: 200,
                    data: {
                        access_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50X2lkIjoxLCJjb250YWN0X2lkIjoyLCJleHAiOjE2ODU0MDQ3OTl9.hLdUp_JgzpgBegrw30Qm_BPtV-5ek9l7ebMWE6dRwKs'
                    }
                }
            )
        }
        return (
            {
                status: 'error',
                message: 'NOT_FOUND',
                code: 404,
                data: {
                    errors: "account.is_null"
                }
            }
        )
    },

}



const fakeBackEnd = {


    getContractDetail(url) {
        return FAKE_DATA[API_CONTRACT_DETAIL](url)
    },

    getContractEvaluteRating(value) {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                if (value) {
                    resolve({
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "id": 1,
                            "score": 5
                        }
                    });
                }
                resolve(
                    {
                        "code": "404",
                        "status": "error",
                        "message": "INVALID_INPUT",
                        "data": {}
                    }
                )
            }, 2000);

        })
        return myPromise
    },

    getMainPerformaceValueThisMonth() {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "data": [
                            {
                                "date": 1623897600,
                                "clicks": 120000,
                                "impressions": 1234134,
                                "conversions": 0
                            },
                            {
                                "date": 1623984000,
                                "clicks": 150000,
                                "impressions": 987654,
                                "conversions": 10
                            },
                            {
                                "date": 1624070400,
                                "clicks": 80000,
                                "impressions": 567890,
                                "conversions": 5
                            },
                            {
                                "date": 1624156800,
                                "clicks": 250000,
                                "impressions": 765432,
                                "conversions": 8
                            },
                            {
                                "date": 1624243200,
                                "clicks": 100000,
                                "impressions": 345678,
                                "conversions": 3
                            },
                            {
                                "date": 1624329600,
                                "clicks": 180000,
                                "impressions": 876543,
                                "conversions": 12
                            },
                            {
                                "date": 1624416000,
                                "clicks": 60000,
                                "impressions": 123456,
                                "conversions": 2
                            },
                            {
                                "date": 1624502400,
                                "clicks": 350000,
                                "impressions": 987654,
                                "conversions": 7
                            },
                            {
                                "date": 1624588800,
                                "clicks": 70000,
                                "impressions": 234567,
                                "conversions": 1
                            },
                            {
                                "date": 1624675200,
                                "clicks": 120000,
                                "impressions": 876543,
                                "conversions": 9
                            }
                        ]
                    }
                });
            }, 2000);

        })
        return myPromise
    },




    getDetached() {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "status": "success",
                    "message": "SUCCESS",
                    "code": 200,
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "contract_id": "1",
                                "contract_value": 123123,
                                "budget": 122341234,
                                "service_fee": 12341234,
                                "vat": 13241234,
                                "fct": 1234,
                                "service_fee_rate": 2354,
                                "exchange_rate": 12312,
                            },

                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })


            }, 2000);
        });
        return myPromise
    },

    getBasicIndicatorsLastMonth() {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        'data': [
                            {
                                "date": 124125346123,
                                "budget": 120000000,
                                "budget_remain": 5000000,
                                "spend": 1234134,
                                "conversions": 0
                            },
                            {
                                "date": 124125346124,
                                "budget": 150000000,
                                "budget_remain": 5000000,
                                "spend": 987654,
                                "conversions": 10
                            },
                            {
                                "date": 124125346125,
                                "budget": 80000000,
                                "budget_remain": 5000000,
                                "spend": 567890,
                                "conversions": 5
                            },
                            {
                                "date": 124125346126,
                                "budget": 250000000,
                                "budget_remain": 5000000,
                                "spend": 765432,
                                "conversions": 8
                            },
                            {
                                "date": 124125346127,
                                "budget": 100000000,
                                "budget_remain": 5000000,
                                "spend": 345678,
                                "conversions": 3
                            },
                            {
                                "date": 124125346128,
                                "budget": 180000000,
                                "budget_remain": 5000000,
                                "spend": 876543,
                                "conversions": 12
                            },
                            {
                                "date": 124125346129,
                                "budget": 60000000,
                                "budget_remain": 5000000,
                                "spend": 123456,
                                "conversions": 2
                            },
                            {
                                "date": 124125346130,
                                "budget": 350000000,
                                "budget_remain": 5000000,
                                "spend": 987654,
                                "conversions": 7
                            },
                            {
                                "date": 124125346131,
                                "budget": 70000000,
                                "budget_remain": 5000000,
                                "spend": 234567,
                                "conversions": 1
                            },
                            {
                                "date": 124125346132,
                                "budget": 120000000,
                                "budget_remain": 5000000,
                                "impressions": 876543,
                                "conversions": 9
                            }
                        ]
                    }
                });
            }, 2000);
        });

        return myPromise
    },

    getBasicIndicatorsThisMonth() {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        'data': [
                            {
                                "date": 124125346123,
                                "budget": 120000000,
                                "budget_remain": 5000000,
                                "spend": 1234134,
                                "conversions": 0
                            },
                            {
                                "date": 124125346124,
                                "budget": 150000000,
                                "budget_remain": 5000000,
                                "spend": 987654,
                                "conversions": 10
                            },
                            {
                                "date": 124125346125,
                                "budget": 80000000,
                                "budget_remain": 5000000,
                                "spend": 567890,
                                "conversions": 5
                            },
                            {
                                "date": 124125346126,
                                "budget": 250000000,
                                "budget_remain": 5000000,
                                "spend": 765432,
                                "conversions": 8
                            },
                            {
                                "date": 124125346127,
                                "budget": 100000000,
                                "budget_remain": 5000000,
                                "spend": 345678,
                                "conversions": 3
                            },
                            {
                                "date": 124125346128,
                                "budget": 180000000,
                                "budget_remain": 5000000,
                                "spend": 876543,
                                "conversions": 12
                            },
                            {
                                "date": 124125346129,
                                "budget": 60000000,
                                "budget_remain": 5000000,
                                "spend": 123456,
                                "conversions": 2
                            },
                            {
                                "date": 124125346130,
                                "budget": 350000000,
                                "budget_remain": 5000000,
                                "spend": 987654,
                                "conversions": 7
                            },
                            {
                                "date": 124125346131,
                                "budget": 70000000,
                                "budget_remain": 5000000,
                                "spend": 234567,
                                "conversions": 1
                            },
                            {
                                "date": 124125346132,
                                "budget": 120000000,
                                "budget_remain": 5000000,
                                "impressions": 876543,
                                "conversions": 9
                            }
                        ]
                    }
                });
            }, 2000);
        });

        return myPromise
    },

    getSliderValueThisMonth() {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data":
                    {
                        "data": [
                            {
                                "date": 1682874000,
                                "impressions": 123123,
                                "clicks": 123,
                                "spend": 2.3,
                                "conversions": 1.1,
                                "cpc": 120212
                            },
                            {
                                "date": 1682960400,
                                "impressions": 12323,
                                "clicks": 23,
                                "spend": 23,
                                "conversions": 1.1,
                                "cpc": 128122
                            },
                            {
                                "date": 1683046800,
                                "impressions": 123123,
                                "clicks": 123,
                                "spend": 23,
                                "conversions": 2.1,
                                "cpc": 32312
                            },
                            {
                                "date": 1683046800,
                                "impressions": 23123,
                                "clicks": 122,
                                "spend": 23,
                                "conversions": 4.1,
                                "cpc": 0
                            },
                        ]
                    }
                })


            }, 2000);
        });
        return myPromise

    },

    getSliderValueLastMonth() {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        'data': [
                            {
                                "date": 1682874000,
                                "campaign_name": "Chiến dịch 1",
                                "impressions": 1223623,
                                "clicks": 391,
                                "spend": 23,
                                "conversions": 1.1,
                                "cpc": 12512
                            },
                        ]
                    }
                })


            }, 2000);
        });
        return myPromise
    },

    getAdCampaignPerformanceLastMonth: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        'data': [
                            {
                                "campaign_id": "1",
                                "date": 1682874000,
                                "campaign_name": "Chiến dịch 1",
                                "impressions": 123623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },
                            {
                                "campaign_id": "2",
                                "date": 1682874000,
                                "campaign_name": "Chiến dịch 2",
                                "impressions": 1223623,
                                "clicks": 391,
                                "cost": 2,
                                "ctr": 1.1,
                                "cpc": 12512
                            },

                        ]
                    }
                })


            }, 2000);
        });
        return myPromise
    },

    getAdGroupPerformanceThisMonth: () => {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "status": "success",
                    "message": "SUCCESS",
                    "code": 200,
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "ABC123"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "DEF456"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "GHI789"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "JKL012"
                            }
                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })


            }, 2000);
        });
        return myPromise
    },


    getAdGroupPerformanceLastMonth: () => {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "status": "success",
                    "message": "SUCCESS",
                    "code": 200,
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1000000,
                                "clicks": 500,
                                "cost": 50,
                                "ctr": 2.5,
                                "cpc": 100,
                                "ad_group_id": "ABC123"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 800000,
                                "clicks": 400,
                                "cost": 40,
                                "ctr": 1.2,
                                "cpc": 80,
                                "ad_group_id": "DEF456"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1200000,
                                "clicks": 600,
                                "cost": 60,
                                "ctr": 1.8,
                                "cpc": 90,
                                "ad_group_id": "GHI789"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1500000,
                                "clicks": 700,
                                "cost": 70,
                                "ctr": 1.5,
                                "cpc": 100,
                                "ad_group_id": "JKL012"
                            }
                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })


            }, 2000);
        });
        return myPromise
    },

    getAdPerformanceByDevice: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "name": "Thiết bị 2",
                                "date": 1682874000,
                                "impressions": 987654,
                                "clicks": 21,
                                "cost": 45,
                                "ctr": 2.2,
                                "cpc": 2468,
                                "ads_segment_id": "ABC123"
                            },
                            {
                                "name": "Thiết bị 1",
                                "date": 1682874000,
                                "impressions": 876543,
                                "clicks": 432,
                                "cost": 67,
                                "ctr": 3.3,
                                "cpc": 9876,
                                "ads_segment_id": "DEF456"
                            },
                            {
                                "name": "Thiết bị 3",
                                "date": 1682874000,
                                "impressions": 765432,
                                "clicks": 543,
                                "cost": 89,
                                "ctr": 4.4,
                                "cpc": 5432,
                                "ads_segment_id": "GHI789"
                            }
                        ],

                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 1,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 1
                    }
                })


            }, 2000);
        });
        return myPromise
    },

    getAdPerformanceByDeviceLastMonth: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "data": [
                            {
                                "name": "2",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 11,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ads_segment_id": "ABC123"
                            },
                            {
                                "name": "1",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ads_segment_id": "DEF456"
                            },
                            {
                                "name": "3",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ads_segment_id": "GHI789"
                            }
                        ],
                    }
                })


            }, 2000);
        });
        return myPromise
    },
    getAdPerformanceByGender: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "current_page": 1,
                        "data": [

                            {
                                "type": "Nữ",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 11,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },
                            {
                                "type": "Nam",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },
                            {
                                "type": "Khác",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },


                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })


            }, 2000);
        });
        return myPromise
    },
    getAdPerformanceByGenderLastMonth: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        'data': [
                            {
                                "type": "1",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },
                            {
                                "type": "2",
                                "date": 1682874000,
                                "impressions": 123623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },


                        ]
                    }
                })


            }, 2000);
        });
        return myPromise
    },

    getImpressions: () => {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "data": [
                            {
                                "date": 1623897600,
                                "impressions": 23,
                                "clicks": 31,
                                "ctr": 17,
                                "cost": 123,
                                "conversions": 0
                            },
                            {
                                "date": 1623984000,
                                "impressions": 23,
                                "clicks": 21,
                                "ctr": 15,
                                "cost": 123,
                                "conversions": 10
                            },
                            {
                                "date": 1624070400,
                                "impressions": 23,
                                "clicks": 31,
                                "ctr": 15,
                                "cost": 123,
                                "conversions": 5
                            },
                            {
                                "date": 1623984000,
                                "impressions": 23,
                                "clicks": 21,
                                "ctr": 15,
                                "cost": 123,
                                "conversions": 10
                            },
                            {
                                "date": 1625070400,
                                "impressions": 23,
                                "clicks": 31,
                                "ctr": 15,
                                "cost": 123,
                                "conversions": 5
                            },
                            {
                                "date": 1626984000,
                                "impressions": 23,
                                "clicks": 21,
                                "ctr": 15,
                                "cost": 123,
                                "conversions": 10
                            },
                            {
                                "date": 1624070400,
                                "impressions": 23,
                                "clicks": 31,
                                "ctr": 15,
                                "cost": 123,
                                "conversions": 5
                            },
                            // Thêm các đối tượng khác vào đây với các giá trị tương ứng
                        ]
                    }
                });
            }, 2000);
        });
        return myPromise
    },

    getListReceiptById: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5,
                        'data': [
                            {
                                "id": 1,
                                "type": "receipt_payment",
                                "status": "paid",
                                "payment_date": 1685341245,
                                "amount": 18150000,
                                "vat": 1650000,
                                "vat_rate": 10,
                                "fct": 0,
                                "fct_rate": 0,
                                "google_service_fee": 0,
                                "google_service_fee_rate": 0,
                                "service_fee": 16500000,
                                "service_fee_rate": 20,
                                "budget": 82500000,
                                "staff_business": {
                                    "display_name": "Tan Huynh Nhat",
                                    "email": "tanhn@adsplus.vn",
                                    "phone": "0999999999",
                                    "avatar_url": "https://example.com/image.jpg"
                                },
                                "staff_accountant": {
                                    "display_name": "Tan Huynh Nhat",
                                    "email": "tanhn@adsplus.vn",
                                    "phone": "0999999999",
                                    "avatar_url": "https://example.com/image.jpg"
                                },
                                "created_at": 1685341245,
                                "updated_at": 1685341245
                            },


                        ]
                    }
                })


            }, 2000);
        });

        return myPromise
    },

    getClickByCountry: () => {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data":
                        [
                            {
                                'canonical_name': 'Thanh Hóa',
                                'clicks': 123445454,
                            },
                            {
                                'canonical_name': 'An Giang',
                                'clicks': 123445454,
                            },
                            {
                                'canonical_name': 'Hà Giang',
                                'clicks': 123445454,
                            },
                            {
                                'canonical_name': 'Huế',
                                'clicks': 123445454,
                            },
                            {
                                'canonical_name': 'Đà Nẵng',
                                'clicks': 123445454,
                            },
                            {
                                'canonical_name': 'Kiên Giang',
                                'clicks': 123445454,
                            },
                        ]
                })


            }, 2000);
        });

        return myPromise
    },

    get(url) {
        return FAKE_DATA[url]()
    },


    post(url, payload) {
        return FAKE_DATA[url](payload)
    },

    getAllService()
    {
        const promise = new Promise(resolve =>
            {
                setTimeout(() =>
                {
                    resolve(
                        {
                            code: 200,
                            status: 'success',
                            message: 'SUCCESS',
                            data: {
                                "current_page": 1,
                                data:
                                [
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "google-ads",
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1
                                    },

                                    {
                                        "date": 1682874010,
                                        "contract_code": "789/012/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "tiktok-ads",
                                        "spend": 2000000,
                                        "budget": 15000000,
                                        "percent_progress": 0.2
                                    },

                                    {
                                        "date": 1682874020,
                                        "contract_code": "345/678/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "youtube-ads",
                                        "spend": 500000,
                                        "budget": 5000000,
                                        "percent_progress": 0.05
                                    }


                                ],
                                "first_page_url": "http://localhost:8000/contracts?page=1",
                                "from": 1,
                                "last_page": 1,
                                "last_page_url": "http://localhost:8000/contracts?page=1",
                                "links": [
                                    {
                                        "url": null,
                                        "label": "pagination.previous",
                                        "active": false
                                    },
                                    {
                                        "url": "http://localhost:8000/contracts?page=1",
                                        "label": "1",
                                        "active": true
                                    },
                                    {
                                        "url": null,
                                        "label": "pagination.next",
                                        "active": false
                                    }
                                ],
                                "next_page_url": null,
                                "path": "http://localhost:8000/contracts",
                                "per_page": 20,
                                "prev_page_url": null,
                                "to": 1,
                                "total": 1
                            }
                        }
                    )
                }, 2000);
            })
        return promise

    },


    getBudgetAllocationRateLastMonth() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            data:
                                [
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "google-ads",
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1
                                    },

                                    {
                                        "date": 1682874010,
                                        "contract_code": "789/012/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "tiktok-ads",
                                        "spend": 2000000,
                                        "budget": 15000000,
                                        "percent_progress": 0.2
                                    },

                                    {
                                        "date": 1682874020,
                                        "contract_code": "345/678/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "youtube-ads",
                                        "spend": 500000,
                                        "budget": 5000000,
                                        "percent_progress": 0.05
                                    }


                                ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 1
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    getBudgetAllocationRateThisMonth() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            data:
                                [
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "google-ads",
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1
                                    },

                                    {
                                        "date": 1682874010,
                                        "contract_code": "789/012/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "tiktok-ads",
                                        "spend": 2000000,
                                        "budget": 15000000,
                                        "percent_progress": 0.2
                                    },

                                    {
                                        "date": 1682874020,
                                        "contract_code": "345/678/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "youtube-ads",
                                        "spend": 500000,
                                        "budget": 5000000,
                                        "percent_progress": 0.05
                                    }


                                ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 1
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    getDataBasicIndicatorsLastTime() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            data:
                                [
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "google-ads",
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1
                                    },
                                ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 1
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    getMainPerformaceValue() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "data": [
                            {
                                "date": 1623897600,
                                "clicks": 120000,
                                "impressions": 1234134,
                                "conversions": 0
                            },
                            {
                                "date": 1623984000,
                                "clicks": 150000,
                                "impressions": 987654,
                                "conversions": 10
                            },
                            {
                                "date": 1624070400,
                                "clicks": 80000,
                                "impressions": 567890,
                                "conversions": 5
                            },
                            {
                                "date": 1624156800,
                                "clicks": 250000,
                                "impressions": 765432,
                                "conversions": 8
                            },
                            {
                                "date": 1624243200,
                                "clicks": 100000,
                                "impressions": 345678,
                                "conversions": 3
                            },
                            {
                                "date": 1624329600,
                                "clicks": 180000,
                                "impressions": 876543,
                                "conversions": 12
                            },
                            {
                                "date": 1624416000,
                                "clicks": 60000,
                                "impressions": 123456,
                                "conversions": 2
                            },
                            {
                                "date": 1624502400,
                                "clicks": 350000,
                                "impressions": 987654,
                                "conversions": 7
                            },
                            {
                                "date": 1624588800,
                                "clicks": 70000,
                                "impressions": 234567,
                                "conversions": 1
                            },
                            {
                                "date": 1624675200,
                                "clicks": 120000,
                                "impressions": 876543,
                                "conversions": 9
                            }
                        ]
                    }
                });
            }, 2000);
        })
        return promise
    },

    getDataBasicIndicatorsThisTime() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            data:
                                [
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "contract_type": "google-ads",
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "budget_reamain": 1002340,
                                        "percent_progress": 0.1
                                    },
                                ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 1
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    getSliderThisTime() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data:
                        {
                            "data": [
                                {
                                    "date": 1682874000,
                                    "impressions": 123123,
                                    "clicks": 123,
                                    "spend": 2.3,
                                    "conversions": 1.1,
                                    "cpc": 120212
                                },
                                {
                                    "date": 1682960400,
                                    "impressions": 12323,
                                    "clicks": 23,
                                    "spend": 23,
                                    "conversions": 1.1,
                                    "cpc": 128122
                                },
                                {
                                    "date": 1683046800,
                                    "impressions": 123123,
                                    "clicks": 123,
                                    "spend": 23,
                                    "conversions": 2.1,
                                    "cpc": 32312
                                },
                                {
                                    "date": 1683046800,
                                    "impressions": 23123,
                                    "clicks": 122,
                                    "spend": 23,
                                    "conversions": 4.1,
                                    "cpc": 0
                                },
                            ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 1
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    getSliderLastTime() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            'data':
                                [
                                    {
                                        "date": 1682874000,
                                        "campaign_name": "Chiến dịch 1",
                                        "impressions": 1223623,
                                        "clicks": 391,
                                        "spend": 23,
                                        "conversions": 1.1,
                                        "cpc": 12512
                                    }
                                ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 1
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    getTableContractPublic() {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    {
                        code: 200,
                        status: 'success',
                        message: 'SUCCESS',
                        data: {
                            "current_page": 1,
                            data:
                                [
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "staff_business": {
                                            "id": 1,
                                            "display_name": "Ads Plus",
                                            "email": "teach@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.png"
                                        },
                                        "staff_advertisers":
                                            [
                                                {
                                                    "id": 2,
                                                    "display_name": "Google staff",
                                                    "email": "googleads@adsplus.vn",
                                                    "phone": "0909999999",
                                                    "avatar_url": "https://example.com/image.png"
                                                }
                                            ],
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1,
                                        "contract_end": 123123123,
                                    },
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "staff_business": {
                                            "id": 1,
                                            "display_name": "Ads Plus",
                                            "email": "teach@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.png"
                                        },
                                        "staff_advertisers":
                                            [
                                                {
                                                    "id": 2,
                                                    "display_name": "Google staff",
                                                    "email": "googleads@adsplus.vn",
                                                    "phone": "0909999999",
                                                    "avatar_url": "https://example.com/image.png"
                                                }
                                            ],
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1,
                                        "contract_end": 123123123,

                                    },
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "staff_business": {
                                            "id": 1,
                                            "display_name": "Ads Plus",
                                            "email": "teach@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.png"
                                        },
                                        "staff_advertisers":
                                            [
                                                {
                                                    "id": 2,
                                                    "display_name": "Google staff",
                                                    "email": "googleads@adsplus.vn",
                                                    "phone": "0909999999",
                                                    "avatar_url": "https://example.com/image.png"
                                                }
                                            ],
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1,
                                        "contract_end": 123123123,

                                    },
                                    {
                                        "date": 1682874000,
                                        "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                        "staff_business": {
                                            "id": 1,
                                            "display_name": "Ads Plus",
                                            "email": "teach@adsplus.vn",
                                            "phone": "0999999999",
                                            "avatar_url": "https://example.com/image.png"
                                        },
                                        "staff_advertisers":
                                            [
                                                {
                                                    "id": 2,
                                                    "display_name": "Google staff",
                                                    "email": "googleads@adsplus.vn",
                                                    "phone": "0909999999",
                                                    "avatar_url": "https://example.com/image.png"
                                                }
                                            ],
                                        "spend": 1000000,
                                        "budget": 10000000,
                                        "percent_progress": 0.1,
                                        "contract_end": 123123123,

                                    },




                                ],
                            "first_page_url": "http://localhost:8000/contracts?page=1",
                            "from": 1,
                            "last_page": 1,
                            "last_page_url": "http://localhost:8000/contracts?page=1",
                            "links": [
                                {
                                    "url": null,
                                    "label": "pagination.previous",
                                    "active": false
                                },
                                {
                                    "url": "http://localhost:8000/contracts?page=1",
                                    "label": "1",
                                    "active": true
                                },
                                {
                                    "url": null,
                                    "label": "pagination.next",
                                    "active": false
                                }
                            ],
                            "next_page_url": null,
                            "path": "http://localhost:8000/contracts",
                            "per_page": 20,
                            "prev_page_url": null,
                            "to": 1,
                            "total": 1
                        }
                    }
                )
            }, 2000);
        })
        return promise
    },

    getPerformaceValue() {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "code": "200",
                    "status": "success",
                    "message": "SUCCESS",
                    "data": {
                        "data": [
                            {
                                "date": 1682874000,
                                "impressions": 123123,
                                "clicks": 123,
                                "ctr": 2133,
                                "conversions": 1.1,
                                "cost": 120212,
                                "spend": 123123,
                            },
                            {
                                "date": 1682960400,
                                "impressions": 12323,
                                "clicks": 23,
                                "ctr": 231,
                                "conversions": 1.1,
                                "cost": 128122,
                                "spend": 1673123,

                            },
                            {
                                "date": 1683046800,
                                "impressions": 123123,
                                "clicks": 123,
                                "ctr": 2133,
                                "conversions": 2.1,
                                "cost": 32312,
                                "spend": 1973123,

                            },
                            {
                                "date": 1683046800,
                                "impressions": 23123,
                                "clicks": 122,
                                "ctr": 23213,
                                "conversions": 4.1,
                                "cost": 12208,
                                "spend": 973123,

                            },

                            // Thêm các đối tượng khác vào đây với các giá trị tương ứng
                        ]
                    }
                });
            }, 2000);
        });
        return myPromise
    },

    getDevicePerformance() {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "status": "success",
                    "message": "SUCCESS",
                    "code": 200,
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "contract_id": "1",
                                "name": "Thiết bị 1",
                                "impressions": 1232623
                            },
                            {
                                "contract_id": "2",
                                "name": "Thiết bị 2",
                                "impressions": 456789
                            },
                            {
                                "contract_id": "3",
                                "name": "Thiết bị 3",
                                "impressions": 987654
                            },
                            // Thêm các mẫu dữ liệu mới ở đây
                            {
                                "contract_id": "4",
                                "name": "Thiết bị 4",
                                "impressions": 246813
                            },
                            {
                                "contract_id": "5",
                                "name": "Thiết bị 5",
                                "impressions": 135792
                            }
                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })
            }, 2000);
        });
        return myPromise
    },


    getGenderPerformance() {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "status": "success",
                    "message": "SUCCESS",
                    "code": 200,
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "contract_id": "1",
                                "type": "Gioi tinh",
                                "impressions": 1232623
                            },
                            {
                                "contract_id": "2",
                                "type": "Gioi tinh",
                                "impressions": 456789
                            },
                            {
                                "contract_id": "3",
                                "type": "Gioi tinh",
                                "impressions": 987654
                            },
                            // Thêm các mẫu dữ liệu mới ở đây
                            {
                                "contract_id": "4",
                                "type": "Gioi tinh",
                                "impressions": 246813
                            },
                            {
                                "contract_id": "5",
                                "type": "Gioi tinh",
                                "impressions": 135792
                            }
                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })
            }, 2000);
        });
        return myPromise
    },

    getAdCampaignPerformanceThisMonth() {
        const myPromise = new Promise((resolve) => {

            setTimeout(() => {
                resolve({
                    "status": "success",
                    "message": "SUCCESS",
                    "code": 200,
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "campaign_id": "1",
                                "date": 1682874000,
                                "campaign_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.3,
                                "cpc": 12512
                            },
                            {
                                "campaign_id": "1",
                                "date": 1682874000,
                                "campaign_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },
                            {
                                "campaign_id": "1",
                                "date": 1682874000,
                                "campaign_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512
                            },
                            {
                                "campaign_id": "1",
                                "date": 1682874000,
                                "campaign_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.9,
                                "cpc": 12512
                            },


                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })


            }, 2000);
        });
        return myPromise
    },




    getAdGroupPerformanceThisTime: () => {
        const myPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "status": "success",
                    "message": "SUCCESS",
                    "code": 200,
                    "data": {
                        "current_page": 1,
                        "data": [
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "ABC123"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "DEF456"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "GHI789"
                            },
                            {
                                "contract_id": "1",
                                "date": 1682874000,
                                "group_name": "Chiến dịch 1",
                                "impressions": 1232623,
                                "clicks": 311,
                                "cost": 23,
                                "ctr": 1.1,
                                "cpc": 12512,
                                "ad_group_id": "JKL012"
                            }
                        ],
                        "first_page_url": "http://localhost:8000/reports?page=1",
                        "from": 1,
                        "last_page": 5,
                        "last_page_url": "http://localhost:8000/reports?page=5",
                        "links": [
                            {
                                "url": null,
                                "label": "pagination.previous",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=1",
                                "label": "1",
                                "active": true
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "2",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=3",
                                "label": "3",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=4",
                                "label": "4",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=5",
                                "label": "5",
                                "active": false
                            },
                            {
                                "url": "http://localhost:8000/reports?page=2",
                                "label": "pagination.next",
                                "active": false
                            }
                        ],
                        "next_page_url": "http://localhost:8000/reports?page=2",
                        "path": "http://localhost:8000/reports",
                        "per_page": 1,
                        "prev_page_url": null,
                        "to": 1,
                        "total": 5
                    }
                })


            }, 2000);
        });
        return myPromise
    },









    put(url, payload) {
        return FAKE_DATA[url](payload)
    },

    getContractPublish: () =>
    {
        const promiss = new Promise( resolve =>
        {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "data":
                            [
                                {
                                    "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                },
                                {
                                    "contract_code": "123/456/GOOGLEADS/ADSPLUS.VN",
                                },
                            ]
                        }
                    }
                )
            }, 2000);
        })
        return promiss
    },

    getContractBudget: () =>
    {
        const promiss = new Promise( resolve =>
        {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "budget": 132454354
                        },
                    }
                )
            }, 1500);
        })
        return promiss
    },

    getContractSpend: () =>
    {
        const promiss = new Promise( resolve =>
        {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "spend": 45492340
                        },
                    }
                )
            }, 3000);
        })
        return promiss
    },


    getContractBudgetRemain: () =>
    {
        const promiss = new Promise( resolve =>
        {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "budget_remain": 15492340
                        }
                    }
                )
            }, 3000);
        })
        return promiss
    },

    getContractNotificationBudget: () =>
    {
        const promiss = new Promise( resolve =>
        {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "data":
                            [
                                {
                                    date: 1682874000,
                                    percent_progress: 0.1,
                                },

                            ]
                        }
                    }
                )
            }, 3000);
        })
        return promiss
    },

    getServiceFree: () =>
    {
        const promiss = new Promise( resolve =>
        {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "spend": 454920
                        }
                    }
                )
            }, 3000);
        })
        return promiss
    },



    getServiceProgress: () =>
    {
        const promiss = new Promise( resolve =>
        {
            setTimeout(() => {
                resolve(
                    {
                        "code": "200",
                        "status": "success",
                        "message": "SUCCESS",
                        "data":
                        {
                            "data":
                            [
                                {
                                    service: 'google-ads',
                                    service_fee_rate: 25
                                },
                                {
                                    service: 'facebook-ads',
                                    service_fee_rate: 25
                                },
                                {
                                    service: 'tiktok-ads',
                                    service_fee_rate: 25
                                },
                                {
                                    service: 'zalo-ads',
                                    service_fee_rate: 25
                                },
                            ]
                        }
                    }
                )
            }, 3000);
        })
        return promiss
    },

    updatePassword: (payload) => {
        let fake_password = 'ducdeptraico102'

        // Check current password don't match password in database
        if (payload.old_password !== fake_password) 
        {
            return {
                status: 'error',
                message: "NOT_FOUND",
                code: 404,
                data: 
                {
                    errors : "account.wrong_infomation"
                }
            }
        }

        // Check new_password matching confirmation_new_password
        if (payload.new_password !== payload.confirm_new_password) 
        {
            return {
                status: 'error',
                message: "INVALID_INPUT",
                code: 400,
                data: 
                {
                    errors : 
                    {
                        confirmation_password: ["validation.same"]
                    }
                }
            }
        }
        
        // Check old_password not difference with new_password
        if (payload.old_password === payload.new_password) 
        {
            return {
                status: 'error',
                message: "NOT_FOUND",
                code: 400,
                data: 
                {
                    errors : "account.wrong_infomation"
                }
            }
        }

        return {
            status: 'success',
            message: "SUCCESS",
            code: 200,
            data: 
            {
                success: "Mật khẩu cập nhật thành công"
            },
            success: true,
        }
    },

    forgotPassword: (email) => {
        const email_check = process.env.email_check
        if (email !== email_check) {
            return {
                status: 400,
                message: "CID không tồn tại trong hệ thống.",
                data: [],
                success: false,
            }
        }

        return {
            status: 200,
            message: "Thông tin đã được gửi đến email tech@adsplus.vn",
            data: [],
            success: true,
        }
    },

    forgetCID: (email) => {
        const email_check = "phamminhquan12c1@gmail.com"
        if (email !== email_check) {
            return {
                code: 400,
                status: 'error',
                message: 'Email không tồn tại trong hệ thống!',
                data: null
            }
        }
        else {
            return {
                code: 200,
                status: 'success',
                message: 'Thông tin đã được gửi đến email tech@adsplus.vn',
                data: []
            }
        }
    },

    resetPWD: (value) => {
        if (value.token !== "1234567") {
            return {
                status: 403,
                message: "Token không hợp lệ.",
                data: [],
                success: false,
            }
        }

        return {
            status: 200,
            message: "Cập nhật mật khẩu thành công.",
            data: [],
            success: false,
        }
    },

    getPermission: (value) => {
        if (!value) {
            return {
                code: 400,
                status: 'error',
                message: 'Tài khoản không hợp lệ!',
                data: []
            }
        }

        return {
            code: 200,
            status: 'success',
            message: 'Lấy phân quyền thành công!',
            data: [
                {
                    module_name: 'contract',
                    permissions: [
                        {
                            name: 'googleads',
                            scopes: [
                                'create',
                                'read',
                            ]
                        }
                    ]
                },
            ]
        }
    }

}
export default fakeBackEnd
