const state = {
    isLoadedGoogleSignIn: false,
    locale: localStorage.getItem('locale') || 'vi',
};

const mutations = {
    CHANGE_IS_LOADED_GOOGLE_SIGN_IN(state, status) {
        state.isLoadedGoogleSignIn = status;
    },
    CHANGE_LOCALE(state, locale) {
        state.locale = locale;
    },
};

const actions = {
    changeIsLoadedGoogleSignIn({ commit }, status) {
        commit("CHANGE_IS_LOADED_GOOGLE_SIGN_IN", status);
    },
    
    changeLocale({ commit }, locale) {
        commit("CHANGE_LOCALE", locale);
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
}