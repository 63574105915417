function load_google_sign_in(store) {
    let oauth = document.createElement("script");
    oauth.setAttribute("type", "application/javascript");
    oauth.setAttribute("src", "https://accounts.google.com/gsi/client");
    oauth.defer = true;
    oauth.onload = () => store.dispatch("app/changeIsLoadedGoogleSignIn", true);
    document.head.appendChild(oauth);
}

export default {
    load_google_sign_in,
};
