import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import { get } from 'lodash';
import store from "../state";

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, left: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const public_pages = [
        'login',
        'forgot-password',
        'forgot-cid',
        'reset-password',
    ];

    const access_token = localStorage.getItem("reset_token");
    const urlParams = new URLSearchParams(window.location.search);
    const accessTokenURL = urlParams.get('reset_token');

    const token = localStorage.getItem('jwt');
    const authRequired = !public_pages.includes(to.name);

    if (!token) {
        if (!authRequired) {
            if (!access_token && to.name === "reset-password") {
                if (!accessTokenURL) {
                    next({ name: "login" });
                    return;
                }
            }
            next();
        } else {
            next({ name: 'login' });
        }
    } else {
        if (!authRequired) {
            next({ name: 'loading' });
        } else {
            next();
        }
    }
});

router.beforeEach((to,from,next)=>
{	
    const public_pages = [
        'login',
        'forgot-password',
        'forgot-cid',
        'reset-password',
        'loading',
        'logout',
        'contract-detail-report-overview',
        'contract-detail-more-info',
    ];

    const permissions = store.state.authentication.permissions;
    const authRequired = !public_pages.includes(to.name);

    if (!permissions) {
        if (authRequired) {
            next({ name: 'loading' });
        } else {
            next();
        }
    } else {
        if (to.meta.permission) {
            if (get(permissions, to.meta.permission)) {
                next();
            } else {
                next({ name: 'home' });
            }
        } else {
            next();
        }
    }
});

router.beforeEach((to, from, next) => {
    const access_token = localStorage.getItem("reset_token");
    const urlParams = new URLSearchParams(window.location.search);
    const accessTokenURL = urlParams.get('reset_token');

    if (!access_token && to.name === "reset-password") {
        if (!accessTokenURL) {
            next({ name: "login" });
            return;
        }
    }

    next();
});

router.beforeResolve(async (to, from, next) => {
    try {
        for (const route of to.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(to, from, (...args) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }
    document.title = to.meta.title;
    next();
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        store.dispatch("layout/changeLoadingRouteChange", true)
    }
    next()
})

router.afterEach((to, from) => {
// Complete the animation of the route progress bar.
    new Promise((resolve) => {
        // Adding a timeout simulates a dynamic route change.
        setTimeout(() => {
            resolve();
        }, 2000);
    })
        .then(() => store.dispatch("layout/changeLoadingRouteChange", false))
})

export default router;
