

import {toast} from 'vue3-toastify'
import 'vue3-toastify/dist/index.css';
import response from '@/configs/message_response.js'
import _get from "lodash/get"

const getToastError = (title, closetime = 2000) =>
{
    let locale = localStorage.getItem('locale') || 'vi'

    title = _get(response, title+ '.message_'+locale, _get(response, `public.SERVER`+ '.message_'+locale))

    toast.error(
        title,
        {
            autoClose: closetime
        }
    )
}

const getToastSuccess = (title, closetime = 2000, position = 'top-right') =>
{
    let locale = localStorage.getItem('locale') || 'vi'

    title = _get(response, title+ '.message_'+locale, _get(response, `public.SUCCESS`+ '.message_'+locale))

    toast.success(
        title,
        {
            autoClose: closetime,
            position: position,
        }
    )
}

export {getToastError, getToastSuccess}

