import { mapState, mapGetters, mapActions } from "vuex";

export const authComputed = {
    ...mapState("auth", {
        currentUser: (state) => state.currentUser,
    }),
    ...mapGetters("auth", ["loggedIn"]),
};

export const layoutComputed = {
    ...mapState("layout", {
        layoutType: (state) => state.layoutType,
        sidebarSize: (state) => state.sidebarSize,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        mode: (state) => state.mode,
        position: (state) => state.position,
        sidebarView: (state) => state.sidebarView,
        sidebarColor: (state) => state.sidebarColor,
        sidebarImage: (state) => state.sidebarImage,
        visibility: (state) => state.visibility,
        loadingRouteChange: (state) => state.loadingRouteChange,
    }),
};

export const permissionComputed = {
     ...mapState('authentication',{
        permission: (state) => state.permissions
     })
}

export const profileComputed = {
    ...mapState('profile',{
        profile: state => state.profile
    })
}

export const authMethods = mapActions("auth", ["logOut", "loginEmailPassword"]);


export const layoutMethods = mapActions("layout", [
    "changeLayoutType",
    "changeLayoutWidth",
    "changeSidebarSize",
    "changeTopbar",
    "changeMode",
    "changePosition",
    "changeSidebarView",
    "changeSidebarColor",
    "changeVisibility",
    "changeLocale"
]);

export const profileMethods = mapActions("profile", [
    "getProfile"
]);

export const notificationMethods = mapActions("notification", [
    "success",
    "error",
    "clear",
]);

export const todoComputed = {
    ...mapState("todo", {
        todos: (state) => state.todos,
    }),
};
export const todoMethods = mapActions("todo", ["fetchTodos"]);