import Swal from "sweetalert2";

const alert = {
    getAlert: (title, type, timer = 4000, showConfirmButton = true) => 
    {
        Swal.fire({
            title: title,
            icon: type,
            showConfirmButton: showConfirmButton,
            timer: timer
        })

    },

    withEmoji: (title, emoji, timer = 1500, showConfirmButton = true) => 
    {
        Swal.fire({
            title: title,
            iconHtml: `<p>${emoji}</p>`,
            showConfirmButton: showConfirmButton,
            customClass: {
                icon: "sweetalert2-no-border"
            },
            timer: timer
        })

    },
}



       
export default alert