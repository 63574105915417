// Authentication
export const API_FORGOT_PWD = `${process.env.VUE_APP_API_URL}/auth/forgot/password`
export const API_RESET_PWD = `${process.env.VUE_APP_API_URL}/auth/forgot/password/reset`

export const API_LOGIN_USER_PWD = `${process.env.VUE_APP_API_URL}/auth/login`
export const API_LOGIN_FIREBASE = `${process.env.VUE_APP_API_URL}/auth/google`
export const API_FORGOT_CID = `${process.env.VUE_APP_API_URL}/auth/forgot/cid`

export const API_PERMISSION = `${process.env.VUE_APP_API_URL}/profile/permissions`
export const API_UPDATE_PASSWORD = `${process.env.VUE_APP_API_URL}/profile/change/password`

export const API_CONTRACT_RECEIPTS = `${process.env.VUE_APP_API_URL}/receipts`
export const API_CONTRACT_RECEIPTS_STATISTIC = `${process.env.VUE_APP_API_URL}/receipts/statistic`
export const API_ACTIVITIES_LATEST = `${process.env.VUE_APP_API_URL}/contracts/activity`
export const API_ACTIVITIES_LATEST_BY_CONTRACT_ID = `${process.env.VUE_APP_API_URL}/contracts/:id/activity`

export const API_PROFILE = `${process.env.VUE_APP_API_URL}/profile`

export const API_FOLLOW_SERVICE  = `${process.env.VUE_APP_API_URL}/profile/follow`
export const API_UN_FOLLOW_SERVICE  = `${process.env.VUE_APP_API_URL}/profile/unfollow`
export const API_PROFILE_UPDATE = `${process.env.VUE_APP_API_URL}/profile`
export const API_PROFILE_SUPPORT = `${process.env.VUE_APP_API_URL}/profile/support`



export const API_CONTRACTS = `${process.env.VUE_APP_API_URL}/contracts/`

export const API_CONTRACT_STATISTIC = `${process.env.VUE_APP_API_URL}/contracts/statistic`


export const API_CONTRACT_LATEST = `${process.env.VUE_APP_API_URL}/contracts/latest`
export const API_CONTRACT_DETAIL = `${process.env.VUE_APP_API_URL}/contracts/:id`

export const API_GET_COMMENTS = `${process.env.VUE_APP_API_URL}/contracts/:id/comments`
export const API_CREATE_COMMENT = `${process.env.VUE_APP_API_URL}/contracts/:id/comments`
export const API_REPLY_COMMENT = `${process.env.VUE_APP_API_URL}/contracts/:id/comments/:parent_id/reply`
export const API_DELETE_COMMENT = `${process.env.VUE_APP_API_URL}/contracts/:id/comments/:comment_id`
export const API_EDIT_COMMENT = `${process.env.VUE_APP_API_URL}/contracts/:id/comments/:comment_id`

export const API_REPORT_CONTRACT = `${process.env.VUE_APP_API_URL}/reports/contract`


export const API_REPORTS_PERFORMANCE = `${process.env.VUE_APP_API_URL}/reports/performance`

export const API_CONTRACTS_EVALUTE_RATING= `${process.env.VUE_APP_API_URL}/contracts/:id/rating`


export const API_REPORTS_CONTRACT = `${process.env.VUE_APP_API_URL}/reports/contract`
export const API_REPORTS_CONTRACT_TYPE = `${process.env.VUE_APP_API_URL}/reports/contract/`
export const API_REPORTS_CONTRACT_SPEND = `${process.env.VUE_APP_API_URL}/reports/contract/spend`

export const API_EXPORT_PERFORMACE_CONTRACT_BY_DAY = `${process.env.VUE_APP_API_URL}/reports/performance/contract/day/export`
export const API_EXPORT_PERFORMACE_CONTRACT_BY_WEEK = `${process.env.VUE_APP_API_URL}/reports/performance/contract/week/export`
export const API_EXPORT_PERFORMACE_CONTRACT_BY_MONTH = `${process.env.VUE_APP_API_URL}/reports/performance/contract/month/export`
export const API_EXPORT_PERFORMACE_CONTRACT_BY_QUARTER = `${process.env.VUE_APP_API_URL}/reports/performance/contract/quarter/export`
export const API_EXPORT_PERFORMACE_CONTRACT_BY_YEAR = `${process.env.VUE_APP_API_URL}/reports/performance/contract/year/export`

export const API_EXPORT_PERFORMACE_AD_ACCOUNT_BY_DAY = `${process.env.VUE_APP_API_URL}/reports/performance/adaccount/day/export`
export const API_EXPORT_PERFORMACE_AD_ACCOUNT_BY_WEEK = `${process.env.VUE_APP_API_URL}/reports/performance/adaccount/week/export`
export const API_EXPORT_PERFORMACE_AD_ACCOUNT_BY_MONTH = `${process.env.VUE_APP_API_URL}/reports/performance/adaccount/month/export`
export const API_EXPORT_PERFORMACE_AD_ACCOUNT_BY_QUARTER = `${process.env.VUE_APP_API_URL}/reports/performance/adaccount/quarter/export`
export const API_EXPORT_PERFORMACE_AD_ACCOUNT_BY_YEAR = `${process.env.VUE_APP_API_URL}/reports/performance/adaccount/year/export`

export const API_EXPORT_FILE_GG_SHEET_CONTRACT = `${process.env.VUE_APP_API_URL}/reports/contract/export`

// API report contract version 2
export const API_REPORT_CONTRACT_BY_DAY = `${process.env.VUE_APP_API_URL}/reports/contract/day`
export const API_REPORT_CONTRACT_STATISTICAL_BY_DAY = `${process.env.VUE_APP_API_URL}/reports/contract/day/statistical`
export const API_REPORT_CONTRACT_SUMMARY_BY_DAY = `${process.env.VUE_APP_API_URL}/reports/contract/day/summary`

export const API_REPORT_CONTRACT_BY_WEEK = `${process.env.VUE_APP_API_URL}/reports/contract/week`
export const API_REPORT_CONTRACT_STATISTICAL_BY_WEEK = `${process.env.VUE_APP_API_URL}/reports/contract/week/statistical`
export const API_REPORT_CONTRACT_SUMMARY_BY_WEEK = `${process.env.VUE_APP_API_URL}/reports/contract/week/summary`

export const API_REPORT_CONTRACT_BY_MONTH = `${process.env.VUE_APP_API_URL}/reports/contract/month`
export const API_REPORT_CONTRACT_STATISTICAL_BY_MONTH = `${process.env.VUE_APP_API_URL}/reports/contract/month/statistical`
export const API_REPORT_CONTRACT_SUMMARY_BY_MONTH = `${process.env.VUE_APP_API_URL}/reports/contract/month/summary`

export const API_REPORT_CONTRACT_BY_QUARTER = `${process.env.VUE_APP_API_URL}/reports/contract/quarter`
export const API_REPORT_CONTRACT_STATISTICAL_BY_QUARTER = `${process.env.VUE_APP_API_URL}/reports/contract/quarter/statistical`
export const API_REPORT_CONTRACT_SUMMARY_BY_QUARTER = `${process.env.VUE_APP_API_URL}/reports/contract/quarter/summary`

export const API_REPORT_CONTRACT_BY_YEAR = `${process.env.VUE_APP_API_URL}/reports/contract/year`
export const API_REPORT_CONTRACT_STATISTICAL_BY_YEAR = `${process.env.VUE_APP_API_URL}/reports/contract/year/statistical`
export const API_REPORT_CONTRACT_SUMMARY_BY_YEAR = `${process.env.VUE_APP_API_URL}/reports/contract/year/summary`


export const API_SYNC_DATA_REPORT_PERFORMANCE = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/sync`

export const API_SYNC_DATA_REPORT_PERFORMANCE_CHECK_REQUEST = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/sync/check-request`

export const API_CUSTOMER_REPORT_PERFORMANCE_AD_ACCOUNT = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_account`
export const API_CUSTOMER_REPORT_PERFORMANCE_AD_CAMPAIGN = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_campaign`
export const API_CUSTOMER_REPORT_PERFORMANCE_AD_GROUP = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_group`
export const API_CUSTOMER_REPORT_PERFORMANCE_AD_GENDER = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_gender`
export const API_CUSTOMER_REPORT_PERFORMANCE_AD_DEVICE = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_device`
export const API_CUSTOMER_REPORT_PERFORMANCE_AD_REGION = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_region`
export const API_CUSTOMER_REPORT_PERFORMANCE_AD = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad`
export const API_CUSTOMER_REPORT_PERFORMANCE_KEYWORD = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_keyword`
export const API_CUSTOMER_REPORT_PERFORMANCE_AD_AGE_RANGE = `${process.env.VUE_APP_API_URL}/v1/customer/reports/performance/ad_age_range`