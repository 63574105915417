import { API_REPORT_CONTRACT, API_ACTIVITIES_LATEST } from "../configs/api"
import fetch from "../helpers/fetch"

class HomeService
{
    static async getContracReport(value)
    {
        const result = await fetch.make().get(API_REPORT_CONTRACT,
        {
            authHeader: true,
            query: value
        })
        return result
    }

    static async getActivitiesLatest(value)
    {
        const result = await fetch.make().get(API_ACTIVITIES_LATEST,
        {
            authHeader: true,
            query: value,
        })
        return result
    }
}


export default HomeService