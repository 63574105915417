import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/index";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/modern/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import '@noction/vue-bezier/dist/style.css'

import MainLayout from './layouts/mainLayout.vue'
import AuthLayout from './layouts/authLayout.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { createHead } from '@vueuse/head';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

import initGTM from './helpers/gtm';

const app = createApp(App)

app.use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .component('main-layout',MainLayout)
    .component('auth-layout',AuthLayout)
    .component('VueDatePicker', VueDatePicker)
    .use(vClickOutside)    
    .use(initGTM.addGtm())
    .use(createHead())
    .mount('#app')

app.config.errorHandler = (err, instance, info) => {
    // handle error, e.g. report to a service
    console.error(err)
}