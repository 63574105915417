import { rules } from "../configs/avatar_rules";
import _isEmpty from 'lodash/isEmpty'

export const isNoAvatar = avatar =>
{

    if( _isEmpty( avatar) )
    {
        return true
    }
    
    return rules.some(
        item => avatar.includes( item )
    )
}


export function getFirstCharacterOfLastWord(str) {
    const words = str.trim().split(/\s+/);
    if( _isEmpty( str ))
    {
        return 'Tên chưa có';
    }
    var lastWord = words[words.length - 1];
    if( lastWord.includes('('))
    {
        lastWord = words[words.length - 2];
    }
    return lastWord.charAt(0).toUpperCase();
  }