export default{
    sideBarIcon: [
        {
            link: '/home',
            href: '',
            permission: 'home',
            icon: 'ri-home-line',
            tooltip: 't-home'
        },
        // {
        //     link: '',
        //     href: '#sidebarApps'
        //     icon: 'lab la-delicious'
        // },
        {
            link: '/dashboard',
            href: '',
            permission: 'dashboard',
            icon: 'ri-dashboard-line',
            tooltip: 't-dashboard'
        },
        {
            link: '/app/contracts',
            href: '',
            permission: 'contract.report.read',
            icon: 'bx bx-briefcase',
            tooltip: 't-contract'
        },
        {
            link: '/app/receipts',
            href: '',
            permission: 'contract.receipt.read',
            icon: 'bx bx-receipt',
            tooltip: 't-payment-list'
        },
        {
            link: '/profile',
            href: '',
            permission: 'profile',
            icon: ' ri-user-3-line',
            tooltip: 't-personal-information'
        },
   
        {
            link: '/admin/reports/contract/wip_in_month',
            href: '',
            permission: 'contract.report.manage',
            icon: 'ri-bar-chart-box-line',
            tooltip: 'Contract WIP in month'
        },
    ]
    ,
    sideBarList: [
        // {
        //     id:'sidebarApps',
        //     list: [
        //         {
        //             link: '/calendar',
        //             id: '',
        //             value: '',
        //             datakey: 't-calendar',
        //             t:'t-calendar',
        //             list: [],
        //         },
        //         {   
        //             id: '#sidebaremail',
        //             value: 'sidebaremail',
        //             link: '',
        //             datakey: 't-projects',
        //             t: 't-email',
        //             list: [
        //                 {   
        //                     id: '#sidebarsubemail',
        //                     value: 'sidebarsubemail',
        //                     datakey: 't-projects',
        //                     t: 't-email-templates',
        //                     link: '',
        //                     list: [
        //                         {   
        //                             link:'/email/email-basic',
        //                             datakey: 't-products',
        //                             t: 't-basic-action',
        //                         },
        //                         {   
        //                             link:'/email/email-ecommerce',
        //                             datakey: 't-products',
        //                             t: 't-ecommerce-action',
        //                         },
        //                     ]
        //                 },
        //                 {
        //                     id: '',
        //                     value: '',
        //                     list: [],
        //                     link: '/mailbox',
        //                     datakey: 't-mailbox',
        //                     t: 't-mailbox'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        {
            id: 'contract',
            list: [
                {
                    link: '/app/contracts',
                    id: '',
                    value: '',
                    datakey: 't-app-contract',
                    t: 't-app-contract',
                    permission: 'contract',
                    list: [],
                    active: 'contract',
                    icon: 'bx bx-briefcase'
                },
                {
                    link: '/app/receipts',
                    id: '',
                    value: '',
                    datakey: 't-app-receipt',
                    t:'t-app-receipt',
                    permission: 'contract',
                    list: [],
                    active: 'receipt',
                    icon: 'bx bx-receipt'
                }
            ], 
        },
    ]
    ,
    sideBarListSm:  [
        // {
        //     link: '',
        //     href: '#sidebarApps'
        //     ,menuValue: 'sidebarApps',
        //     icon: 'lab la-delicious',
        //     id:'sidebarApps',
        //     t: 't-apps',
        //     module_name: 'contract',
        //     list: [
        //         {
        //             link: '/calendar',
        //             id: '',
        //             value: '',
        //             datakey: 't-calendar',
        //             t:'t-calendar',
        //             list: [],
        //             module_name: 'facebook',

        //         },
        //         {   
        //             id: '#sidebaremail',
        //             value: 'sidebaremail',
        //             link: '',
        //             datakey: 't-projects',
        //             t: 't-email',
        //             module_name: 'google',
        //             list: [
        //                 {   
        //                     id: '#sidebarsubemail',
        //                     value: 'sidebarsubemail',
        //                     datakey: 't-projects',
        //                     t: 't-email-templates',
        //                     link: '',
        //                     module_name: 'facebook',
        //                     list: [
        //                         {   
        //                             link:'/email/email-basic',
        //                             datakey: 't-products',
        //                             t: 't-basic-action',
        //                         },
        //                         {   
        //                             link:'/email/email-ecommerce',
        //                             datakey: 't-products',
        //                             t: 't-ecommerce-action',
        //                         },
        //                     ]
        //                 },
        //                 {
        //                     id: '',
        //                     value: '',
        //                     list: [],
        //                     link: '/mailbox',
        //                     datakey: 't-mailbox',
        //                     module_name: 'íntagram',
        //                     t: 't-mailbox'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        {
            link: '/home',
            href: ''
            ,menuValue: 'home',
            icon: 'ri-home-line',
            id: 'home',
            t: 't-home',
            permission: 'home',
            list: []
        },
        {
            link: '/dashboard',
            href: ''
            ,menuValue: 'dashboard',
            icon: 'ri-dashboard-line',
            id: 'dashboard',
            t: 't-dashboards',
            permission: 'dashboard',
            list: []
        },
        
        {
            link: '',
            href: '#contract',
            value: 'contract',
            icon: 'lab la-delicious',
            t: 't-apps',
            id: 'contract',
            permission: 'contract',
            list: [
                {
                    link: '/app/contracts',
                    id: '',
                    value: '',
                    datakey: 't-app-contract',
                    t:'t-app-contract',
                    list: [],
                    permission: 'contract',
                },
                {
                    link: '/app/receipts',
                    id: '',
                    value: '',
                    datakey: 't-app-receipt',
                    t:'t-app-receipt',
                    permission: 'contract',
                    list: [],
                }
            ]
        },

        {
            link: '/profile',
            href: ''
            ,menuValue: 'profile',
            icon: ' ri-user-3-line',
            id: 'profile',
            t: 't-profile',
            permission: 'profile',
            list: []
        },
        
    ]
}
