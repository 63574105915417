import { createGtm } from '@gtm-support/vue-gtm';
import router from "@/router";
import {GTM_ID, GTM_NONCE, GTM_ENABLED, GTM_DEFER, GTM_COMPATIBILITY, GTM_DEBUG, GTM_LOADSCRIPT} from '@/configs/gtm.js';

class GTM {
    constructor(id=GTM_ID, vueRouter=router, nonce=GTM_NONCE, defer=GTM_DEFER, compatibility=GTM_COMPATIBILITY, enabled=GTM_ENABLED, debug=GTM_DEBUG, loadScript=GTM_LOADSCRIPT, trackOnNextTick=false) {
        this.id = id
        this.defer = defer
        this.compatibility =compatibility
        this.nonce = nonce
        this.enabled = enabled
        this.debug = debug
        this.loadScript = loadScript
        this.trackOnNextTick = trackOnNextTick
        this.vueRouter = vueRouter
    }
    addGtm(){
        if(this.id && GTM_ENABLED)
        {
            try {
                return createGtm({
                    id: this.id,
                    defer: this.defer,
                    compatibility: this.compatibility,
                    nonce: this.nonce,
                    enabled: this.enabled,
                    debug: this.debug,
                    loadScript: this.loadScript,
                    vueRouter: this.vueRouter,
                    trackOnNextTick: this.trackOnNextTick,
                })
            }
            catch (error) {
                return 
            }
        }
        return 
        
    }
}
const initGTM = new GTM(); 
export default initGTM