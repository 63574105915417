<script>
import lottie from '../components/widgets/lottie.vue'
import { layoutComputed } from '../state/helpers'
import animationData from '@/components/widgets/route-loading.json'
export default {
    components: { lottie },
    data() {
        return {
            animationData,
        }
    },
    computed: {
        ...layoutComputed
    }
}
</script>

<template>
    <div v-if="loadingRouteChange" :style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        inset: 0,
        zIndex: 999,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(1px)',
    }">
        <div>
            <lottie :options="{ animationData }" :height="60" :width="60"
            />
        </div>
    </div>
            
</template>
