
export const GTM_ID= process.env.VUE_APP_GTM_ID
export const GTM_NONCE= process.env.VUE_APP_GTM_NONCE 
export const GTM_ROUTER= process.env.VUE_APP_GTM_VUE_ROUTER
export const GTM_ENABLED= process.env.VUE_APP_GTM_ENABLED?.toLowerCase()  == 'true'
export const GTM_DEFER= process.env.VUE_APP_GTM_DEFER?.toLowerCase()  == 'true'
export const GTM_COMPATIBILITY= process.env.VUE_APP_GTM_COMPATIBILITY?.toLowerCase()  == 'true'
export const GTM_DEBUG= process.env.VUE_APP_GTM_DEBUG?.toLowerCase()  == 'true'
export const GTM_LOADSCRIPT= process.env.VUE_APP_GTM_LOADSCRIPT?.toLowerCase()  == 'true'

