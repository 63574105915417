

const permissions = {
    contact: 
    {
        profile: 
        {
            read: true,
            update: true
        }
    },
    
    contract: 
    {
        googleads: 
        {
            read: true,
            create: true,
        },
        
        facebookads: 
        {
            read: true
        },
        
        tiktokads: 
        {
            read: true
        },
        
        receipt: 
        {
            read: true
        },
        
        invoice: 
        {
            read: true
        },
        
        report: 
        {
            read: true
        }
    },
    
    report: 
    {
        google_performance: 
        {
            read: true
        },
        
        facebook_performance: 
        {
            read: true
        },
        
        tiktok_performance: 
        {
            read: true
        }
    },
    
    account: 
    {
        info: 
        {
            read: true,
            update: true
        }
    }
}

export {permissions}

