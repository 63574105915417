
import 
{
    API_REPORTS_PERFORMANCE,
    API_REPORTS_CONTRACT,
    API_REPORTS_CONTRACT_TYPE,
    API_REPORTS_CONTRACT_SPEND,
    API_SYNC_DATA_REPORT_PERFORMANCE,
    API_SYNC_DATA_REPORT_PERFORMANCE_CHECK_REQUEST,
    API_REPORT_CONTRACT_SUMMARY_BY_YEAR,
    API_REPORT_CONTRACT_SUMMARY_BY_DAY,
    API_REPORT_CONTRACT_SUMMARY_BY_WEEK,
    API_REPORT_CONTRACT_SUMMARY_BY_MONTH,
    API_REPORT_CONTRACT_SUMMARY_BY_QUARTER,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_ACCOUNT,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_CAMPAIGN,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_GROUP,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_GENDER,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_DEVICE,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_REGION,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_KEYWORD,
    API_CUSTOMER_REPORT_PERFORMANCE_AD,
    API_CUSTOMER_REPORT_PERFORMANCE_AD_AGE_RANGE
} from '../configs/api'
import fetch from "../helpers/fetch";

class DashboardService
{
    static async getReportPerformance(value)
    {

        const result = await fetch.make().get(API_REPORTS_PERFORMANCE,
        {
            authHeader: true,
            query: value.query
        })
        return result
    }

    static async getReportPerformanceGroup(value, mode = 'ad_account')
    {
        let temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_ACCOUNT

        switch(mode)
        {
            case 'ad':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD;
                break;
            case 'ad_account':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_ACCOUNT;
                break;
            case 'ad_group':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_GROUP;
                break;
            case 'ad_campaign':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_CAMPAIGN;
                break;
            case 'ad_gender':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_GENDER;
                break;
            case 'ad_device':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_DEVICE;
                break;
            case 'ad_region':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_REGION;
                break;
            case 'ad_keyword':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_KEYWORD;
                break;
            case 'ad_age_range':
                temp_api = API_CUSTOMER_REPORT_PERFORMANCE_AD_AGE_RANGE;
                break;
            default:
                break;
        }

        const result = await fetch.make().get(temp_api,
        {
            authHeader: true,
            query: value.query
        })
        return result
    }

    static async getReportContract(value)
    {
        const result = await fetch.make().get(API_REPORTS_CONTRACT, 
        {
            authHeader: true,
            query: value.query
        })
        return result
    
    }

    static async getReportContractType(value)
    {
        const result = await fetch.make().get(API_REPORTS_CONTRACT_TYPE, 
        {
            authHeader: true,
            query: value.query
        })
        return result
    
    }

    static async getReportContractSpend(value)
    {
        const result = await fetch.make().get(API_REPORTS_CONTRACT_SPEND, 
        {
            authHeader: true,
            query: value.query
        })
        return result
    
    }

    static async syncDateReportPerformance(view_types)
    {

        const result = await fetch.make().post(API_SYNC_DATA_REPORT_PERFORMANCE,
        view_types,
        {
            authHeader: true,
        })
        return result
    }


    static async syncDateReportPerformanceCheckRequest(request_id)
    {
        const result = await fetch.make().post(API_SYNC_DATA_REPORT_PERFORMANCE_CHECK_REQUEST,
        request_id,
        {
            authHeader: true,
        },)
        return result
    }

     /**
     * Retrieves the report summary based on the specified dimension and parameters.
     *
     * @param {string} dimension - The dimension of the report (day, week, month, quarter, year).
     * @param {object} parameters - The parameters for the report.
     * @return {Promise<object>} - The report summary.
     */
     static async getReportSummaryV2(dimension, paramerters)
     {
         let endpoint = API_REPORT_CONTRACT_SUMMARY_BY_YEAR;
         
         switch(dimension)
         {
             case 'day':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_DAY;
                break;
             case 'week':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_WEEK;
                break;
             case 'month':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_MONTH;
                break;
             case 'quarter':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_QUARTER;
                break;
             case 'year':
                endpoint = API_REPORT_CONTRACT_SUMMARY_BY_YEAR;
                break;
             default:
                break;
        }
 
        const result = await fetch.make().get(endpoint, 
            { 
                authHeader: true,
                query: paramerters
            })
 
        return result
    }
}


export default DashboardService