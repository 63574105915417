import moment from "moment";
import store from '../state/index'
import get from "lodash/get"

export function calculateDimension(from, to) 
{
    // Tạo các đối tượng moment từ các timestamp
    const fromDate = moment.unix(from);
    const toDate = moment.unix(to);

    // Tính khoảng thời gian
    const durationInDays = toDate.diff(fromDate, 'days');
    const durationInWeeks = toDate.diff(fromDate, 'weeks');
    const durationInMonths = toDate.diff(fromDate, 'months');
    const durationInQuarters = toDate.diff(fromDate, 'quarters');

    // Tính dimension
    let dimension = "year";
    if (durationInDays < 1) 
    {
        dimension = "day";
    } 
    else if (durationInWeeks < 1) 
    {
        dimension = "week";
    } 
    else if (durationInMonths < 1) 
    {
        dimension = "month";
    } 
    else if (durationInQuarters < 1) 
    {
        dimension = "quarter";
    }

    return dimension;
}


export const get_filter_time_by_dimension = (dimension, is_previous = false, mode='timestamp') => {
    const dimension_enum = ['day', 'week','month', 'quarter' ,'year']
    if(dimension_enum.includes(dimension))
    {
        let now = moment();
        if(is_previous)
        {
            now = now.subtract(1, dimension);
        }
    
        let start = now.clone().startOf(dimension);
        let end = now.clone().endOf(dimension);
        if('week' == dimension)
        {
            start = now.clone().startOf('isoWeek');
            end = now.clone().endOf('isoWeek');
        }
    
        let config = {
            from: start.unix(),
            to: end.unix(),
            dimension: dimension
        };
    
        if('timestamp' != mode)
        {
            config.from = moment.unix(config.from).format("YYYY-MM-DD")
            config.to = moment.unix(config.to).format("YYYY-MM-DD")
        }
    
        return config; 
    }

    let config = {
        from: dimension[0],
        to: dimension[1],
        dimension: calculateDimension(dimension[0], dimension[1])
    };

    if (is_previous) 
    {
        let time_temp = moment.unix(config.from).subtract(1, config.dimension); // Sử dụng moment.unix để xử lý timestamp
        config.from = time_temp.clone().startOf(config.dimension).unix(); // Lấy timestamp cho đầu kỳ
        config.to = time_temp.clone().endOf(config.dimension).unix(); // Lấy timestamp cho cuối kỳ
    }
    
    if('timestamp' != mode)
    {
        config.from = moment.unix(config.from).format("YYYY-MM-DD")
        config.to = moment.unix(config.to).format("YYYY-MM-DD")
    }

    return config
}

export const get_filter_time_by_dimension_contract = (dimension, is_previous = false, mode='timestamp') => {
    const dimension_enum = ['day', 'week','month', 'quarter' ,'year', 'all']
    if(dimension_enum.includes(dimension))
    {  if('all' == dimension)
        {
            const contractDetail =  store.state.contract.contractDetail
            let from = contractDetail.advertise_start_time
            let to = moment().clone().endOf("day").unix()

            if('timestamp' != mode)
            {
                from = moment.unix(from).format("YYYY-MM-DD")
                to = moment.unix(to).format("YYYY-MM-DD")
            }
            return {
                from,
                to,
                dimension: 'all'
            }
        }

        let now = moment();
        if(is_previous)
        {
            now = now.subtract(1, dimension);
        }

        let start = now.clone().startOf(dimension);
        let end = now.clone().endOf(dimension);
        if('week' == dimension)
        {
            start = now.clone().startOf('isoWeek');
            end = now.clone().endOf('isoWeek');
        }

        let config = {
            from: start.unix(),
            to: end.unix(),
            dimension: dimension
        };
        if('timestamp' != mode)
        {
            config.from = moment.unix(config.from).format("YYYY-MM-DD")
            config.to = moment.unix(config.to).format("YYYY-MM-DD")
        }
        return config;
    }
    const contractDetail =  store.state.contract.contractDetail
    let time_start_of_date = moment.unix(get(contractDetail, 'advertise_start_time')).startOf('day')
    
    let temp_demension_from = get(dimension, '[0]')
    if (!temp_demension_from)
    {
        temp_demension_from = time_start_of_date.unix()
    }

    let config = {
        from: temp_demension_from,
        to: get(dimension, '[1]', moment().clone().endOf('day').unix()) ,
        dimension: calculateDimension(temp_demension_from, get(dimension, '[1]', moment().clone().endOf('day').unix()))
    }

    if (is_previous) 
    {
        let time_temp = moment.unix(config.from).subtract(1, config.dimension); // Sử dụng moment.unix để xử lý timestamp
        config.from = time_temp.clone().startOf(config.dimension).unix(); // Lấy timestamp cho đầu kỳ
        config.to = time_temp.clone().endOf(config.dimension).unix(); // Lấy timestamp cho cuối kỳ
    }

    if('timestamp' != mode)
    {
        config.from = moment.unix(config.from).format("YYYY-MM-DD")
        config.to = moment.unix(config.to).format("YYYY-MM-DD")
    }

    return config;
}